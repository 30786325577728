// Import the functions you need from the SDKs you need
import firebase from "firebase/compat/app";
import { getAnalytics } from "firebase/analytics";
import "firebase/compat/database";
import "firebase/compat/messaging";
import "firebase/compat/firestore";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyC3NoC1p1MKMD5kb8RLnGwBZvz8yQuOP_Y",
  authDomain: "escape-spa-4dfd9.firebaseapp.com",
  projectId: "escape-spa-4dfd9",
  storageBucket: "escape-spa-4dfd9.appspot.com",
  messagingSenderId: "268414308708",
  appId: "1:268414308708:web:69177a08bdcddd92232a71",
  measurementId: "G-6NLDM2095H",
};

// Initialize Firebase
// const app = initializeApp(firebaseConfig);
!firebase.apps.length ? firebase.initializeApp(firebaseConfig) : firebase.app();

export default firebase;

export const database = firebase.database();
export const firestore = firebase.firestore();
export const messaging = firebase.messaging();
