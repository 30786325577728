import moment from "moment";
import "./BSServicesBox.css";

const BSServicesBox = ({ data }) => {
  return (
    <>
      <div className="bs_services_box">
        <div className="bs_services_box__row">
          <div className="bs_service_name">{data ? data.service_name : ""}</div>

          <div className="bs_service_employee">
            {data ? data.employee_name : ""}
          </div>
        </div>
        <div className="bs_services_box__row">
          <div className="bs_service_name">Date</div>

          <div className="bs_service_employee">
            {moment(data.start_datetime).format("DD MMM, YYYY")}
          </div>
        </div>
        <div className="bs_services_box__row">
          <div className="bs_service_name">Time</div>

          <div className="bs_service_employee">
            {moment(data.start_datetime).format("HH:mm") +
              " - " +
              moment(data.end_datetime).format("HH:mm")}
          </div>
        </div>
        <div className="bs_services_box__row">
          <div className="bs_service_name">Price</div>

          <div className="bs_service_employee">{data.price} SAR</div>
        </div>
      </div>
    </>
  );
};

export default BSServicesBox;
