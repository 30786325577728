export const VALIDATION_REGEX = {
  DIGITS_ONLY: /^[0-9]*$/,
  DIGITIS_WITH_DECIMAL_POINT: /^[0-9]\d*(\.\d+)?$/,
  ALPHABETS_ONLY: /^[a-zA-Z ]+$/,
  CAPITAL_LETTERS: /^[A-Z ]+$/,
  EMAIL_REGEX: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w\w+)+$/,
  PHONE:
    /^((\+\d{1,3}(-| )?\(?\d\)?(-| )?\d{1,5})|(\(?\d{2,6}\)?))(-| )?(\d{3,4})(-| )?(\d{4})(( x| ext)\d{1,5}){0,1}$/,
  PAKISTAN_NUMBER: /^(\+92|0|92)[0-9]{10}$/,
  PHONE_NUMBER_SAUDIA: /^(009665|9665|\+9665|05|5)(5|0|3|6|4|9|1|8|7)([0-9]{7})$/,
};

export const isSaudiPhoneNumberInvalid = (value) => {
  return !VALIDATION_REGEX.PHONE_NUMBER_SAUDIA.test(value);
};
