import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export function CustomError(msg) {
  toast.error(msg, {
    position: "bottom-right",
    autoClose: 3000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    className: "toast__msg",
  });

  //   return (
  //     <div>
  //       <ToastContainer />
  //     </div>
  //   );
}

export function NotificationMessage(notification) {
  toast.info(<Display notification={notification} />);

  function Display({ notification }) {
    let message = JSON.parse(notification.data.notificationData);

    return (
      <div>
        <h4 style={{ color: "black" }}>{message.title}</h4>
        <p style={{ color: "black" }}>{message.body}</p>
      </div>
    );
  }
}
