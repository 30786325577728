import "./App.css";
import "./Variables.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Login from "./components/Login/Login";
import Navbar from "./components/Navbar/Navbar";
import Footer from "./components/Footer/Footer";
import ReceptionistDashboard from "./components/Receptionist Dashboard/ReceptionistDashboard";
import AppointmentDetails from "./components/Appointment Details/AppointmentDetails";
import { useEffect, useState } from "react";
import Invoice from "./components/Invoice/Invoice";
import AllEmployees from "./components/AllEmployees/AllEmployees";
import Transactions from "./components/Transactions/Transactions";
import DailyBalances from "./components/DailyBalances/DailyBalances";
import ModifyBooking from "./components/ModifyBooking/ModifyBooking";
import BillingSummary from "./components/BillingSummary/BillingSummary";
import BookAppointment from "./components/BookAppointment/BookAppointment";
import NewBookAppointment from "./components/BookAppointment/NewBookAppointment";
import { ToastContainer } from "react-toastify";
import { LoadScript } from "@react-google-maps/api";
import firebase, { messaging } from "./Server/firebase";
import { NotificationMessage } from "./components/Toast";
import AddEmployee from "./components/AllEmployees/AddEmployee/AddEmployee";

function App() {
  const [islogin, setislogin] = useState(false);

  useEffect(() => {
    if (firebase.messaging.isSupported()) {
      firebase
        .messaging()
        .getToken({
          vapidKey:
            "BMDl3y2a1lRFq_CXIWoDmI3kuNRIbOh7AJuoWexiVL4xANmnkoWEpTx_e82ewjoYsULSTeR0ahl1Ll0TL4VW6go",
        })
        .then((currentToken) => {

          if (currentToken) {
            onMessageListener();
            // console.log("currentToken", currentToken);
            window.localStorage.setItem("ft", currentToken);
          } else
            console.error(
              "No registration token available. Request permission to generate one."
            );
        })
        .catch((err) => {
          console.error(err);
        });
    }
    // window.addEventListener("popstate", handleEvent);
    // return () => window.removeEventListener("popstate", handleEvent);
  });

  const onMessageListener = () =>
    new Promise((resolve) => {

      // if (firebase.messaging.isSupported()) {
      firebase.messaging().onMessage((payload) => {
        resolve(payload);
        // setOrderCreatedNotify(!orderCreatedNotify);
        NotificationMessage(payload);
        // dispatch(updateNotification(payload));
      });
      // }
    });

  return (
    <>
      <LoadScript
        id="google-map-script-loader"
        googleMapsApiKey={"AIzaSyAGpL9qLO2D5V6ToKYEvAQZ7YymBRmB9Pk"}
        libraries={["places"]}
        loadingElement={<></>}
      >
        <BrowserRouter>
          <Navbar islogin={islogin} />
          <Routes>
            <Route path="/" element={<Login setislogin={setislogin} />} />
            <Route
              path="/receptionist_dashboard"
              element={<ReceptionistDashboard />}
            />
            <Route
              path="/appointmentdetails"
              element={<AppointmentDetails />}
            />
            <Route path="/invoice" element={<Invoice />} />
            <Route path="/allemployees" element={<AllEmployees />} />
            <Route path="/add-employee" element={<AddEmployee />} />
            <Route path="/transactions" element={<Transactions />} />
            <Route path="/daily_balances" element={<DailyBalances />} />
            <Route path="/modifybooking" element={<ModifyBooking />} />
            <Route path="/billingsummary" element={<BillingSummary />} />
            <Route path="/book_appointment" element={<NewBookAppointment />} />
          </Routes>
          <ToastContainer />
          <Footer />
        </BrowserRouter>
      </LoadScript>
    </>
  );
}

export default App;
