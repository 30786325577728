import moment from "moment";
import { useNavigate } from "react-router-dom";
import "./BookAppBookingsRow.css";
import { Plus } from "react-feather";

const BookAppBookingsRow = ({ data, onDeleteClick, onAddClick, index, isLoading }) => {
  // const setDate = (data) => {
  //   var splittedDate = data.start_datetime.split("T");
  //   return splittedDate[0];
  // };

  // const setTime = (data) => {
  //   var splittedStartTime = data.start_datetime.split("T");
  //   var newStartTime = splittedStartTime[1].split(":00.");
  //   var splittedEndTime = data.end_datetime.split("T");
  //   var newEndTime = splittedEndTime[1].split(":00.");
  //   return newStartTime[0] + " - " + newEndTime[0];
  // };

  return (
    <>
      <tr className="book_app_booking_row_positions">
        <td className="book_app_booking_row_td">{data.service_name}</td>
        {/* <td className="book_app_booking_row_td">{setDate(data)}</td> */}
        <td className="book_app_booking_row_td">
          {moment(data.start_datetime).format("YYYY-MM-DD")}
        </td>
        {/* <td className="book_app_booking_row_td">{setTime(data)}</td> */}
        <td className="book_app_booking_row_td">{`${moment(data.start_datetime).format(
          "HH:mm"
        )} - ${moment(data.end_datetime).format("HH:mm")}`}</td>
        <td className="book_app_booking_row_td">{data.employee_name}</td>
        <td className="book_app_booking_row_td">{data.price}</td>
        {/* <td className="book_app_booking_row_td">
          <button className="book_app_row_edit_btn" onClick={() => onEdit(data)}>
            Edit
          </button>
        </td> */}
        <td className="book_app_booking_row_td">
          <button className="book_app_row_cancel_btn" onClick={() => onDeleteClick(data, index)}>
            Cancel
          </button>
        </td>
        {/* <td className="book_app_booking_row_td">
          <button
            className="book_app_row_edit_btn"
            style={data.is_disabled || isLoading ? { background: "grey" } : null}
            disabled={data.is_disabled}
            onClick={() => {
              onAddClick(data, index);
            }}
          >
            <Plus size={16} />
          </button>
        </td> */}
      </tr>
    </>
  );
};

export default BookAppBookingsRow;
