import "./AllEmployees.css";
import LeafImage from "../../img/leaf.png";
import EmployeeCard from "./EmployeeCard/EmployeeCard";
import { getAllEmployeeSchedule } from "../../api/apicalls";
import { useEffect, useState } from "react";
import { PuffLoader } from "react-spinners";
import { useNavigate } from "react-router-dom";
import moment from "moment";

const AllEmployees = () => {
  const navigate = useNavigate();
  const branch_id = localStorage.getItem("branchId");
  const [employees, setEmployees] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const user = localStorage.getItem("receptionistId");

  useEffect(() => {
    if (!user) navigate("/");
  }, []);

  useEffect(() => {
    getAllEmployeesApi();
  }, []);

  const getAllEmployeesApi = () => {
    let obj = {
      Model: {
        branch_id: parseInt(branch_id),
        currentDate: moment(new Date()).format("YYYY-MM-DDTHH:mm:ss"),
        day: new Date().getDay() + 1,
      },
    };
    setIsLoading(true);
    getAllEmployeeSchedule(obj)
      .then((res) => {
        setIsLoading(false);

        if (res.data.data.getAllEmployeeSchedule.error_code === 0) {
          setEmployees(res.data.data.getAllEmployeeSchedule.result);
        } else setEmployees([]);
      })
      .catch((err) => {
        setIsLoading(false);
        console.log("err", err);
      });
  };

  return (
    <>
      <div className="cover_image">
        <p className="reception_heading">Employees</p>
      </div>

      {/* <div>
        <img src={LeafImage} alt="" className="leaf leaf_pos" />
      </div> */}

      <div className="allemployees_container_wrapper">
        <div className="allemployees_container">
          <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
            <div className="all_emp_heading">Employees</div>

            <div
              style={{
                backgroundColor: "#2b3738",
                borderRadius: "10px",
                padding: "1em",
                color: "white",
                cursor: "pointer",
              }}
              onClick={() => {
                navigate("/add-employee");
                window.scrollTo(0, 0);
              }}
            >
              + Add Employee
            </div>
          </div>

          <div className="emp_cards_positioning">
            {isLoading ? (
              <div className="loader__container">
                <PuffLoader
                  loading={isLoading}
                  size={150}
                  aria-label="Loading Spinner"
                  data-testid="loader"
                />
              </div>
            ) : employees.length > 0 ? (
              employees.map((data) => <EmployeeCard data={data} />)
            ) : (
              <div className="no__record__container__wrapper">
                <div className="loader__container">No Employees</div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default AllEmployees;
