import React, { useRef, useCallback, useState } from "react";
import { GoogleMap, MarkerF, InfoWindow } from "@react-google-maps/api";

export default function MapView({ onPickLocation }) {
  const [lat, setLat] = useState(31.473664);
  const [lng, setLng] = useState(74.4030208);

  const center = { lat, lng };

  const [map, setMap] = useState(null);
  const [activeMarker, setActiveMarker] = useState(null);

  const handleActiveMarker = (marker) => {
    if (marker === activeMarker) {
      return;
    }
    setActiveMarker(marker);
  };

  const onUnmount = useCallback(function callback(map) {
    setMap(null);
  }, []);

  return (
    <div style={{ width: "100%", overflowX: "hidden" }}>
      <GoogleMap
        mapContainerClassName="map-container"
        center={center}
        zoom={15.2}
        onUnmount={onUnmount}
        onClick={(e) => {
          let latitude = e.latLng.lat();
          let longtitude = e.latLng.lng();
          setLat(latitude);
          setLng(longtitude);
          onPickLocation(latitude, longtitude);
        }}
      >
        <MarkerF
          id={1}
          position={{
            lat: parseFloat(lat),
            lng: parseFloat(lng),
          }}
          onClick={() => handleActiveMarker(1)}
        ></MarkerF>
      </GoogleMap>
    </div>
  );
}
