import "./DailyBalances.css";
import { useEffect, useState } from "react";
import { PuffLoader } from "react-spinners";
import { getDailyTransactionReportCall } from "../../api/apicalls";
import moment from "moment";

export default function DailyBalances() {
  const [state, setState] = useState({
    selectedDate: moment(new Date()).format("YYYY-MM-DD"),
    dailyBalances: [],
    page: 1,
    limit: 1000,
    totalRecords: 0,
  });
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    getDailyBalances();
  }, [state.page, state.selectedDate]);

  function getDailyBalances() {
    const receptionistId = localStorage.getItem("receptionistId");
    setIsLoading(true);
    let endDate = new Date(state.selectedDate);
    endDate = endDate.setDate(endDate.getDate() + 1);
    getDailyTransactionReportCall(
      receptionistId,
      state.selectedDate ? moment(state.selectedDate).format("YYYY-MM-DDT06:00:00") : null,
      endDate ? moment(endDate).format("YYYY-MM-DDT06:00:00") : null,
      state.page,
      state.limit
    )
      .then(({ data }) => {
        setIsLoading(false);
        if (data.error_code == 0) {
          setState((prevState) => ({
            ...prevState,
            dailyBalances: [data.result],
            totalRecords: data.total_records,
          }));
        } else {
          setState((prevState) => ({
            ...prevState,
            dailyBalances: [],
            totalRecords: 0,
          }));
        }
      })
      .catch((err) => {
        console.log("err", err);
        setIsLoading(false);
      });
  }

  return (
    <>
      <div className="cover_image">
        <p className="reception_heading">Daily Balances</p>
      </div>
      <div className="transactions_container_wrapper">
        <div className="transactions_container">
          <div className="transactions_container__filter__section">
            <div className="transactions_container__filter__section__container">
              <input
                className="transactions_container__filter__section__container__date"
                type="date"
                value={state.selectedDate}
                onChange={(e) => {
                  setState((prevState) => ({ ...prevState, selectedDate: e.target.value }));
                }}
              />
            </div>
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
              backgroundColor: "white",
              borderRadius: "20px",
              justifyContent: "center",
            }}
          >
            <table className="transactions__table">
              <thead>
                <tr>
                  <th>Payment Mode</th>
                  <th>Amount</th>
                </tr>
              </thead>
              <tbody style={{ borderRadius: "0px" }}>
                {state.dailyBalances.map((row, index) => (
                  <>
                    <tr>
                      <td>{"Cash"}</td>
                      <td>{`SAR ${row.cash_price}`}</td>
                    </tr>
                    <tr>
                      <td>{"Card"}</td>
                      <td>{`SAR ${row.credit_card_price}`}</td>
                    </tr>
                    <tr>
                      <td>{"Online"}</td>
                      <td>{`SAR ${row.online_price}`}</td>
                    </tr>
                    <tr>
                      <td>{"Bank"}</td>
                      <td>{`SAR ${row.total_bank}`}</td>
                    </tr>
                    <tr>
                      <td>{"Total"}</td>
                      <td>{`SAR ${row.total_price}`}</td>
                    </tr>
                  </>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      {isLoading && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <PuffLoader
            loading={isLoading}
            size={150}
            aria-label="Loading Spinner"
            data-testid="loader"
          />
        </div>
      )}
    </>
  );
}
