import "./EditPaymentType.css";
import Modal from "react-modal";
import { useEffect, useState } from "react";
import Select from "react-select";
import { changeBookingPaymentTypeCall, changeBookingStatus } from "../../../api/apicalls";
import { useNavigate } from "react-router-dom";
import { CustomError } from "../../Toast";

const disableScrollOnOpen = () => {
  document.body.style.overflow = "hidden";
};
const disableScrollOnClose = () => {
  document.body.style.overflow = "auto";
};

const InvoicePayment = ({ isEditPaymentType, setIsEditPaymentType, bookingData, onCallBack }) => {
  const navigate = useNavigate();
  const [bookingTransactions, setBookingTransactions] = useState([]);
  const [selectedBookingTransactions, setSelectedBookingTransactions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    console.log("bookingData", bookingData);
    if (bookingData)
      setBookingTransactions(JSON.parse(JSON.stringify(bookingData.booking_transcations)));
  }, [bookingData]);

  const onHandleValueChange = (item, index) => {
    let newArray = [...bookingTransactions];
    newArray[index].new_payment_type_id = item.value;
    setBookingTransactions(newArray);
  };

  const onHandleEditPaymentType = () => {
    const receptionistId = localStorage.getItem("receptionistId");

    let newArray = [];
    for (let index = 0; index < bookingTransactions.length; index++) {
      const element = bookingTransactions[index];
      let objectttt = {
        old_payment_type_id: element.payment_type_id,
        new_payment_type_id: element.new_payment_type_id
          ? element.new_payment_type_id
          : element.payment_type_id,
      };
      newArray.push(objectttt);
    }

    let object = {
      bookingId: bookingData.id,
      paymentDetails: newArray,
    };
    setIsLoading(true);
    changeBookingPaymentTypeCall(object)
      .then(({ data }) => {
        setIsLoading(false);
        if (data.error_code == 0) {
          onCallBack(data.result.booking_transcations);
          setIsEditPaymentType(false);
          // navigate("/receptionist_dashboard");
        } else CustomError(data.message);
      })
      .catch((err) => {
        setIsLoading(false);
        console.log("err", err);
      });
  };

  return (
    <Modal
      className={"inv_payment_container"}
      overlayClassName="modal_Overlay"
      onAfterOpen={disableScrollOnOpen}
      onAfterClose={disableScrollOnClose}
      isOpen={isEditPaymentType}
      shouldCloseOnOverlayClick={true}
      onRequestClose={() => {
        setIsEditPaymentType(false);
      }}
      shouldCloseOnEsc={true}
    >
      <div className="inv_payment_heading">Edit Payment Type</div>

      <div className="inv_payment_paymentmethod_dropdown_wrapper" style={{ marginTop: "20px" }}>
        {bookingData.booking_transcations.map((item, index) => (
          <ModifyPaymentType index={index} item={item} onHandleValueChange={onHandleValueChange} />
        ))}
      </div>

      <button className="inv_payment_pay_btn" onClick={() => onHandleEditPaymentType()}>
        {isLoading ? "----" : "Confirm"}
      </button>
    </Modal>
  );
};

const ModifyPaymentType = ({ item, index, onHandleValueChange }) => {
  const [paymentTypes] = useState([
    { value: 10, label: "Cash", id: item.payment_type_id },
    { value: 9, label: "Online", id: item.payment_type_id },
    { value: 23, label: "Card", id: item.payment_type_id },
    { value: 24, label: "Bank Transfer", id: item.payment_type_id },
  ]);
  const [selectedType, setSelectedType] = useState(null);

  useEffect(() => {
    setSelectedType({
      value: item.payment_type_id,
      label:
        item.payment_type_id == 10
          ? "Cash"
          : item.payment_type_id == 9
          ? "Online"
          : item.payment_type_id == 23
          ? "Card"
          : "Bank Transfer",
    });
  }, []);

  return (
    <>
      <div style={{ fontWeight: "bold", fontSize: "18px" }}>
        Price: <span style={{ fontWeight: "normal", fontSize: "16px" }}>{item.price}</span>
      </div>
      <Select
        options={paymentTypes}
        value={selectedType}
        name="payment_types"
        onChange={(value) => {
          setSelectedType(value);
          onHandleValueChange(value, index);
        }}
        className="book_app_customer_input__payment"
        theme={(theme) => ({
          ...theme,
          borderRadius: 0,
          colors: {
            ...theme.colors,
            primary75: "#2a5e59",
            primary25: "#2a5e595e",
            primary50: "#2a5e595e",
            primary: "#2a5e59",
          },
        })}
        styles={{
          control: (base, state) => ({
            ...base,
            "&:hover": {
              borderColor: "transparent",
              borderBottom: "1px solid #DFDFDF",
            }, // border style on hover
            border: "1px solid transparent", // default border color
            boxShadow: "none", // no box-shadow
            borderRadius: "30px",
            marginRight: "0.5em",
            background: "#EFEFEF",
            fontFamily: "Montserrat",
            height: "50px",
          }),
        }}
      />
    </>
  );
};

export default InvoicePayment;
