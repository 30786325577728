import "./Login.css";
import LeafImage from "../../img/leaf.png";
import CoverImage from "../../img/coverimage.png";
import { useEffect, useState } from "react";
import { LoginApiCall } from "../../api/apicalls";
import { useNavigate } from "react-router-dom";
import { CustomError } from "../Toast";
import flag from "../../img/ksa-flag.png";
// import { toast, ToastContainer } from 'react-toastify';

// 510078602
// 12345678

const Login = ({ setislogin }) => {
  const navigate = useNavigate();
  const [number, setnumber] = useState("");
  const [password, setpassword] = useState("");

  const [inperrmsg, setinperrmsg] = useState("");
  const [inppswerrmsg, setinppswerrmsg] = useState("");
  const [isloading, setisloading] = useState(false);

  const user = localStorage.getItem("receptionistId");

  useEffect(() => {
    if (user) navigate("/receptionist_dashboard");
  }, []);

  const checkformat = (str) => {
    var phoneno = /^(009665|9665|\+9665|05|5)(5|0|3|6|4|9|1|8|7)([0-9]{7})$/;
    if (str.match(phoneno)) {
      return true;
    } else {
      return false;
    }
  };
  const update_number = (event) => {
    if (!isNaN(event.currentTarget.value)) {
      setnumber(event.target.value);
      if (event.target.value === "" || event.target.value === null) {
        setinperrmsg("");
      } else if (checkformat(event.target.value) === false) {
        setinperrmsg("Please enter valid number");
      } else {
        setinperrmsg("");
      }
    }
  };

  const update_password = (event) => {
    setpassword(event.target.value);
    setinppswerrmsg("");
  };

  // const notify = () => {
  //     toast('Number or Password is wrong');
  // }

  const validate_number = () => {
    if (!number) setinperrmsg("Please enter your number.");
    else if (number.length != 9) setinperrmsg("Please enter valid number");
    else if (!password) setinppswerrmsg("Please enter your password");
    else {
      let obj = {
        login: {
          phone_number: "966" + number,
          password: password,
          fCM_token_web: localStorage.getItem("ft"),
        },
      };

      setisloading(true);
      LoginApiCall(obj)
        .then((res) => {
          if (res.data.data.login.error_code === 0 && res.data.data.login.result.user_role == 2) {
            localStorage.setItem("branchName", res.data.data.login.result.branch.name);
            localStorage.setItem("branchId", res.data.data.login.result.branch.id);
            localStorage.setItem("receptionistId", res.data.data.login.result.id);
            localStorage.setItem("openingTime", res.data.data.login.result.branch.opening_time);
            localStorage.setItem("closingTime", res.data.data.login.result.branch.closing_time);
            localStorage.setItem("slotInterval", res.data.data.login.result.branch.slot_interval);
            localStorage.setItem("type", res.data.data.login.result.branch.type);

            localStorage.setItem(
              "receptionistName",
              res.data.data.login.result.employees[0].first_name +
                " " +
                res.data.data.login.result.employees[0].last_name
            );
            setislogin(true);
            navigate("/receptionist_dashboard", {
              state: { datatosend: res.data.data.login.result },
            });
          } else {
            // notify();
            if (res.data.data.login.result && res.data.data.login.result.user_role != 2)
              CustomError("Invalid Credentials");
            else CustomError(res.data.data.login.message);
            setisloading(false);
          }
        })
        .catch((err) => {
          setisloading(false);
          console.log("err", err);
        });
    }
  };

  // document.addEventListener("keyup", function(event) {
  //     if (event.keyCode === 13) {
  //         validate_number();
  //     }
  // });

  return (
    <>
      <div className="login_main_container">
        {/* <div>
          <img src={LeafImage} alt="" className="leaf" />
        </div> */}

        <div className="login_main_screen">
          <div className="login_main_screen_left">
            <div className="login_left_heading">Welcome</div>

            <p className="login_left_para">
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Vel illum beatae quae animi,
              facilis maiores dolor architecto sunt sapiente eveniet placeat quaerat incidunt.
              Reiciendis, dolores architecto? Necessitatibus nobis quo accusamus.
            </p>

            <div className="login_input_box">
              {/* <div className="login_input_field_container">
                <img src={flag} />
                <div style={{ fontSize: "1.5rem", margin: "0em .5em" }}>+966</div>
                <input
                  type="text"
                  name="number"
                  id="number"
                  value={number}
                  className="login_input_number"
                  placeholder="Number"
                  onChange={(event) => update_number(event)}
                  onKeyDown={(event) => {
                    if (event.key === "Enter") validate_number();
                  }}
                />
                <div className="login_inp_error_msg">{inperrmsg ? inperrmsg : ""}</div>
              </div> */}

              <div className="Login_Number_detailinput_container">
                <div className="phone__input__wrapper">
                  <img src={flag} />
                  <div style={{ fontSize: "0.9rem", margin: "0em .5em" }}>+966</div>
                  <input
                    type="text"
                    className="Login_input__box__new"
                    style={{ paddingLeft: "0.5em" }}
                    placeholder={"Number"}
                    value={number}
                    maxLength={9}
                    onChange={(event) => {
                      if (!isNaN(event.target.value)) {
                        if (event.target.value.length !== 9) {
                          setnumber(event.target.value);
                          setinperrmsg("Please enter valid number.");
                        } else {
                          setnumber(event.target.value);
                          setinperrmsg("");
                        }
                      }
                    }}
                  />
                </div>
              </div>
              <div
                className="error__message"
                style={{ alignSelf: "flex-start", marginLeft: "6.8em" }}
              >
                {inperrmsg ? inperrmsg : null}
              </div>

              <div className="login_input_field_container">
                <input
                  type="password"
                  name="password"
                  id="password"
                  className="login_input_password"
                  placeholder="Password"
                  onChange={(event) => update_password(event)}
                  onKeyDown={(event) => {
                    if (event.key === "Enter") validate_number();
                  }}
                />
                <div className="login_inp_error_msg">{inppswerrmsg ? inppswerrmsg : ""}</div>
              </div>
              <button
                className="login_signin_btn"
                onClick={(event) => {
                  validate_number();
                }}
              >
                {isloading ? "Processing..." : "Login"}
              </button>
            </div>
          </div>

          <div className="login_main_screen_right">
            <img src={CoverImage} alt="CoverImage" />
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
