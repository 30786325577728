import React, { useState, useEffect } from "react";
import "./AddEmployee.css";
import Select from "react-select";
import { useLocation, useNavigate } from "react-router-dom";
// import {
//   addOrEditEmployee,
//   addOrEditEmployeeImage,
//   getAllServices,
//   getEmployeeById,
// } from "../../../api/apicalls";
import { isNullOrEmptyArray } from "../../../Utils/isNullOrEmptyArray";
import { isNullOrEmpty } from "../../../Utils/isNullOrEmpty";
import { isSaudiPhoneNumberInvalid } from "../../../Utils/isSaudiPhoneNumberInvalid";
import moment from "moment";
import { CustomError } from "../../Toast";
import userAvatar from "../../../img/userAvatar.png";
import Icon from "../../../img/Cam_Icon.svg";
import Edit from "../../../img/Edit_Icon.svg";
import Delete from "../../../img/Delete_Icon.svg";
import { addOrEditEmployee, addOrEditEmployeeImage, getAllServices } from "../../../api/apicalls";

export default function AddEmployee() {
  let navigate = useNavigate();
  const location = useLocation();
  const today = new Date().toISOString().split("T")[0];
  const [employeeEditObj, setEmployeeEditObj] = useState(null);
  const [id, setId] = useState(0);
  const [userId, setUserId] = useState("");
  const [branches, setBranches] = useState("");
  const [branchesErrMsg, setBranchesErrMsg] = useState("");
  let [allBranchesArr, setAllBranchesArr] = useState([]);
  const [salary, setSalary] = useState("");
  const [salaryErrMsg, setSalaryErrMsg] = useState("");
  const [firstName, setFirstName] = useState("");
  const [firstNameErrMsg, setFirstNameErrMsg] = useState("");
  const [lastName, setLastName] = useState("");
  const [lastNameErrMsg, setLastNameErrMsg] = useState("");
  let [commission, setCommission] = useState("");
  let [commissionErrMsg, setCommissionErrMsg] = useState("");
  const [services, setServices] = useState([]);
  const [servicesErrMsg, setServicesErrMsg] = useState("");
  let [allServiceArr, setAllServicesArr] = useState([]);
  const [mobile, setMobile] = useState("");
  const [mobileErrMsg, setMobileErrMsg] = useState("");
  const [password, setPassword] = useState("");
  const [passwordErrMsg, setPasswordErrMsg] = useState("");
  const [dob, setDob] = useState("");
  const [dobErrMsg, setDobErrMsg] = useState("");
  const [gender, setGender] = useState("");
  const [genderErrMsg, setGenderErrMsg] = useState("");
  const [description, setDescription] = useState("");
  const [descriptionErrMsg, setDescriptionErrMsg] = useState("");
  const [descriptionAr, setDescriptionAr] = useState("");
  const [descriptionArErrMsg, setDescriptionArErrMsg] = useState("");
  const [isActive, setIsActive] = useState(false);
  const [currentAvailability, setCurrentAvailability] = useState(0);

  const [employeeImage, setEmployeeImage] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const [schedule, setSchedule] = useState([]);
  const [indexOfShiftToEdit, setIndexOfShiftToEdit] = useState(null);
  const [showAddShift, setShowAddShift] = useState(false);
  const [newShiftDay, setNewShiftDay] = useState("");
  const [newShiftStartTime, setNewShiftStartTime] = useState("");
  const [newShiftEndTime, setNewShiftEndTime] = useState("");
  const [newShiftStartTimeSecond, setNewShiftStartTimeSecond] = useState("");
  const [newShiftEndTimeSecond, setNewShiftEndTimeSecond] = useState("");
  const [shiftExistsErrMsg, setShiftExistsErrMsg] = useState("");

  const genderOptions = [
    { value: 0, label: "Male" },
    { value: 1, label: "Female" },
  ];
  const daysOptions = [
    { value: 1, label: "Sunday" },
    { value: 2, label: "Monday" },
    { value: 3, label: "Tuesday" },
    { value: 4, label: "Wednesday" },
    { value: 5, label: "Thursday" },
    { value: 6, label: "Friday" },
    { value: 7, label: "Saturday" },
  ];
  const dateEndIndex = 11;

  useEffect(() => {
    if (indexOfShiftToEdit == null) {
      // setShiftToEdit(null)
    } else {
      let shiftToEditObj = schedule.at(indexOfShiftToEdit);
      setNewShiftDay(daysOptions.find((d) => d.value == shiftToEditObj.day));
      setNewShiftStartTime(
        shiftToEditObj.start ? moment(shiftToEditObj.start).format("HH:mm:00") : null
      );
      setNewShiftEndTime(shiftToEditObj.end ? moment(shiftToEditObj.end).format("HH:mm:00") : null);
      setNewShiftStartTimeSecond(
        shiftToEditObj.start_1 ? moment(shiftToEditObj.start_1).format("HH:mm:00") : null
      );
      setNewShiftEndTimeSecond(
        shiftToEditObj.end_1 ? moment(shiftToEditObj.end_1).format("HH:mm:00") : null
      );
    }
  }, [indexOfShiftToEdit]); //Set values to form on click of shift edit

  useEffect(() => {
    fetchAllServices();
  }, []);

  const onCommissionParcentageTextChangeListener = (event) => {
    let string = event.target.value;
    let arr = [];
    let wordsWithoutSpace = "";
    arr = string.split(" ").filter(function (n) {
      return n !== "";
    });
    arr.map((data) => {
      wordsWithoutSpace = data;
    });
    setCommission((commission = wordsWithoutSpace));
    if (commission > 99) setCommissionErrMsg("Commission can't be greater than 99.");
    else setCommissionErrMsg("");
  };

  const isViewValid = () => {
    // if (isNullOrEmpty(branches)) setBranchesErrMsg("Please select branch.");
    if (isNullOrEmpty(salary)) setSalaryErrMsg("Please enter salary.");
    else if (isNullOrEmpty(firstName)) setFirstNameErrMsg("Please enter first name.");
    else if (isNullOrEmpty(commission)) setCommissionErrMsg("Please enter commission.");
    else if (commission > 99) setCommissionErrMsg("Commission can't be greater than 99.");
    else if (isNullOrEmpty(lastName)) setLastNameErrMsg("Please enter last name.");
    else if (isNullOrEmpty(services) || isNullOrEmptyArray(services))
      setServicesErrMsg("Please select service.");
    else if (isNullOrEmpty(mobile)) setMobileErrMsg("Please enter number.");
    else if (isSaudiPhoneNumberInvalid(mobile)) setMobileErrMsg("Enter valid number.");
    else if (isNullOrEmpty(password)) setPasswordErrMsg("Please enter password.");
    else if (password.length < 8) setPasswordErrMsg("Password can't be less than 8.");
    else if (isNullOrEmpty(dob)) setDobErrMsg("Please enter date of birth.");
    else if (isNullOrEmpty(gender)) setGenderErrMsg("Please select gender.");
    else if (isNullOrEmpty(description)) setDescriptionErrMsg("Please enter description.");
    else if (isNullOrEmpty(descriptionAr)) setDescriptionArErrMsg("Please enter descriptionAr.");
    else return true;
    return false;
  };

  const fetchAllServices = () => {
    let arr = [];
    getAllServices(100000, 1)
      .then(({ data }) => {
        if (data.result != null) {
          for (let index = 0; index < data.result.length; index++) {
            const element = data.result[index];
            let obj = {
              label: element.name,
              value: element.id,
            };
            arr.push(obj);
          }
          setAllServicesArr((allServiceArr = arr));
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  const onClickSave = () => {
    if (isViewValid()) {
      let branchId = localStorage.getItem("branchId");

      let servicesArray = [];
      if (services.length > 0) {
        for (let index = 0; index < services.length; index++) {
          const element = services[index];
          let obj = {
            id: element.id ? element.id : 0,
            service_id: parseInt(element.value),
            employee_id: element.employeeId ? element.employeeId : 0,
          };
          servicesArray.push(obj);
        }
      }

      let requestObj = {
        user: {
          id: userId ? userId : 0,
          phone_number: mobile,
          password: password,
          role_id: 3,
        },
        id: id ? id : 0,
        user_id: userId ? userId : 0,
        branch_id: parseInt(branchId),
        description: description,
        description_ar: descriptionAr,
        first_name: firstName,
        first_name_ar: firstName,
        last_name: lastName,
        last_name_ar: lastName,
        gender: parseInt(gender.value),
        dob: moment(dob).format("YYYY-MM-DDT00:00:00"),
        status: 0,
        salary: parseInt(salary),
        commission_pct: parseInt(commission),
        action_type: isActive ? 2 : 4,
        services: servicesArray,
        current_availability: currentAvailability,
        schedule: schedule,
      };
      setIsLoading(true);
      addOrEditEmployee(requestObj)
        .then(({ data }) => {
          setIsLoading(false);

          if (data.error_code == 0) {
            if (typeof employeeImage != "string") onAddImage(data.result.id);
            else {
              navigate("/allemployees");
              window.scrollTo(0, 0);
            }
          } else if (data.error_code == 3) {
            CustomError("Phone number already exists.");
          } else {
            CustomError(data.message);
          }
        })
        .catch((err) => {
          setIsLoading(false);
          CustomError("Failed, please try again.");
        });
    }
  };

  const onAddImage = (userId) => {
    var bodyFormData = new FormData();
    bodyFormData.append("id", userId);
    bodyFormData.append("image_url", employeeImage);
    if (typeof employeeImage != "string") bodyFormData.append("image_file", employeeImage);
    addOrEditEmployeeImage(bodyFormData)
      .then(({ data }) => {
        if (data.error_code == 0) {
          navigate("/allemployees");
          window.scrollTo(0, 0);
        } else {
          CustomError(data.message);
        }
      })
      .catch((err) => {
        CustomError("Failed, please try again.");
      });
  };
  //#endregion

  const handleSubmitShift = () => {
    if (!newShiftStartTime) setShiftExistsErrMsg("Select first start time.");
    else if (!newShiftEndTime) setShiftExistsErrMsg("Select first end time.");
    else if (newShiftStartTime > newShiftEndTime)
      setShiftExistsErrMsg("First start time can't be greater than end time");
    else if (newShiftStartTimeSecond && !newShiftEndTimeSecond)
      setShiftExistsErrMsg("Select second end time.");
    else if (newShiftStartTimeSecond && newShiftStartTimeSecond < newShiftEndTime)
      setShiftExistsErrMsg("Second start time can't be less than end first time");
    else if (
      newShiftStartTimeSecond &&
      newShiftEndTimeSecond &&
      newShiftStartTimeSecond > newShiftEndTimeSecond
    )
      setShiftExistsErrMsg("Second start time can't be greater than end time");
    else {
      let currentSchedule = [...schedule];
      const today = moment(new Date()).format("YYYY-MM-DDT");

      const shiftObj = {
        id: 0,
        employee_id: id,
        day: parseInt(newShiftDay.value),
        start: `${today}${
          newShiftStartTime.length == 5 ? newShiftStartTime + ":00" : newShiftStartTime
        }`,
        end: `${today}${newShiftEndTime.length == 5 ? newShiftEndTime + ":00" : newShiftEndTime}`,
        start_1: newShiftStartTimeSecond
          ? `${today}${
              newShiftStartTimeSecond.length == 5
                ? newShiftStartTimeSecond + ":00"
                : newShiftStartTimeSecond
            }`
          : null,
        end_1: newShiftEndTimeSecond
          ? `${today}${
              newShiftEndTimeSecond.length == 5
                ? newShiftEndTimeSecond + ":00"
                : newShiftEndTimeSecond
            }`
          : null,
      };

      //If indexOfShiftToEdit is null, add as new shift
      currentSchedule =
        indexOfShiftToEdit != null
          ? updateShift(currentSchedule, shiftObj)
          : addNewShift(currentSchedule, shiftObj);

      if (currentSchedule != null) {
        setSchedule(currentSchedule);
        resetShiftForm();
      } else {
        let errMsg = "";

        // indexOfShiftToEdit != null ?
        // errMsg = "Operation failed. The selected values are the same as the original shift you are attempting to edit."   //If edit
        // :
        // errMsg = "Operation failed. This shift already exists."   //If add
        // ;

        errMsg = "Operation failed. This shift conflicts with an existing shift.";

        setShiftExistsErrMsg(errMsg);
      }
    }
  };

  const addNewShift = (currentSchedule, shiftObj) => {
    //If same shift does not exist
    // if(!currentSchedule.some(e => e.day === shiftObj.day && e.start.substring(dateEndIndex) === shiftObj.start.substring(dateEndIndex) && e.end.substring(dateEndIndex) === shiftObj.end.substring(dateEndIndex))){

    if (!doesShiftConflict(currentSchedule, shiftObj, false)) {
      currentSchedule.push(shiftObj);
      setSchedule(currentSchedule);
      return currentSchedule;
    }

    return null;
  };

  const updateShift = (currentSchedule, shiftObj) => {
    //If same shift does not exist
    // if(!currentSchedule.some(e => e.day === shiftObj.day && e.start.substring(dateEndIndex) === shiftObj.start.substring(dateEndIndex) && e.end.substring(dateEndIndex) === shiftObj.end.substring(dateEndIndex))){

    if (!doesShiftConflict(currentSchedule, shiftObj, true)) {
      let itemToUpdate = currentSchedule.at(indexOfShiftToEdit);
      //itemToUpdate.id = 0;
      itemToUpdate.day = shiftObj.day;
      itemToUpdate.start = shiftObj.start;
      itemToUpdate.end = shiftObj.end;
      itemToUpdate.start_1 = shiftObj.start_1;
      itemToUpdate.end_1 = shiftObj.end_1;
      return currentSchedule;
    }

    return null;
  };

  const doesShiftConflict = (currentSchedule, shiftObj, isEdit) => {
    for (const shiftObjToCheck of currentSchedule) {
      //If edit allow to change if objects are identical
      if (isEdit) {
        if (
          !areShiftsIdentical(shiftObjToCheck, currentSchedule.at(indexOfShiftToEdit)) &&
          shiftObjToCheck.day === shiftObj.day &&
          isDateRangeConflict(shiftObjToCheck, shiftObj)
        ) {
          return true; // Conflict found
        }
      } else {
        if (
          shiftObjToCheck.day === shiftObj.day &&
          isDateRangeConflict(shiftObjToCheck, shiftObj)
        ) {
          return true; // Conflict found
        }
      }
    }
    return false; // No conflicts
  };
  const isDateRangeConflict = (shiftObj1, shiftObj2) => {
    const timeFormat = "HH:mm";
    const start1Str = moment(shiftObj1.start).format(timeFormat);
    const end1Str = moment(shiftObj1.end).format(timeFormat);
    const start2Str = moment(shiftObj2.start).format(timeFormat);
    const end2Str = moment(shiftObj2.end).format(timeFormat);

    const start1 = new Date(`1970-01-01T${start1Str}:00`);
    const end1 = new Date(`1970-01-01T${end1Str}:00`);
    const start2 = new Date(`1970-01-01T${start2Str}:00`);
    const end2 = new Date(`1970-01-01T${end2Str}:00`);

    // Check for conflicts
    if (start1 <= end2 && end1 >= start2) {
      // There is a conflict
      return true;
    } else {
      // No conflict
      return false;
    }
  };

  const areShiftsIdentical = (shiftObj1, shiftObj2) => {
    let isIdentical =
      shiftObj1.day === shiftObj2.day &&
      shiftObj1.start === shiftObj2.start &&
      shiftObj1.end === shiftObj2.end &&
      shiftObj1.start_1 === shiftObj2.start_1 &&
      shiftObj1.end_1 === shiftObj2.end_1;

    return isIdentical;
  };

  const resetShiftForm = () => {
    setNewShiftDay("");
    setNewShiftStartTime("");
    setNewShiftEndTime("");
    setNewShiftStartTimeSecond("");
    setNewShiftEndTimeSecond("");

    setShiftExistsErrMsg("");

    setIndexOfShiftToEdit(null);

    setShowAddShift(false);
  };

  const showShiftForm = (isEdit, item, indexOfItemToEdit) => {
    setShowAddShift(true);
    if (isEdit) {
      setIndexOfShiftToEdit(parseInt(indexOfItemToEdit));
    }
  };

  const removeShift = (index) => {
    let currentSchedule = [...schedule];
    currentSchedule.splice(index, 1);

    setSchedule(currentSchedule);
  };

  const onSelectImage = (img) => {
    if (img.type.includes("png") || img.type.includes("jpg") || img.type.includes("jpeg")) {
      setEmployeeImage(img);
    } else {
      CustomError("Invalid File");
    }
  };

  return (
    <>
      <div className="cover_image">
        <p className="reception_heading">Add Employee</p>
      </div>
      <div className="Add_Emp_Main_Container">
        <div className="Add_Emp_Content_Main_Container">
          <section className="Add_Emp_Content_Fist_Wrapper">
            <div>
              {/* <div className={"Add_Emp_Category_Select_Container"}>
                <Select
                  placeholder="Branch"
                  options={allBranchesArr}
                  className={"Add_Emp_Category_Select_Option_Style"}
                  classNamePrefix="Select"
                  value={branches}
                  onChange={(event) => {
                    setBranches(event);
                    setBranchesErrMsg("");
                  }}
                  components={{
                    IndicatorSeparator: () => null,
                  }}
                  isSearchable={false}
                  styles={{
                    control: (baseStyles, state) => ({
                      ...baseStyles,
                      background: "#F5F5F5",
                      borderColor: state.isFocused ? "white" : null,
                      borderRadius: "25px",
                      border: state.isFocused ? 0 : 0,
                      outline: state.isFocused ? "none" : "none",
                      boxShadow: "none",
                      cursor: "pointer",
                      paddingLeft: "27px",
                      paddingRight: "27px",
                    }),
                  }}
                />
              </div>
              <div className="Add_Emp_Error__message">{branchesErrMsg ? branchesErrMsg : null}</div> */}

              <div className="Add_Emp_detailinput_container_Input">
                <input
                  type="text"
                  className="Add_Emp_input__box"
                  placeholder="First name"
                  value={firstName}
                  onChange={(event) => {
                    setFirstName(event.target.value);
                    setFirstNameErrMsg("");
                  }}
                />

                <div className="Add_Emp_Error__message">
                  {firstNameErrMsg ? firstNameErrMsg : null}
                </div>
              </div>

              <div className="Add_Emp_detailinput_container_Input">
                <input
                  type="text"
                  className="Add_Emp_input__box"
                  placeholder="Last name"
                  value={lastName}
                  onChange={(event) => {
                    setLastName(event.target.value);
                    setLastNameErrMsg("");
                  }}
                />
                <div className="Add_Emp_Error__message">
                  {lastNameErrMsg ? lastNameErrMsg : null}
                </div>
              </div>

              <div className="Add_Emp_detailinput_container_Input">
                <input
                  type="text"
                  className="Add_Emp_input__box"
                  placeholder="Mobile"
                  value={mobile}
                  onChange={(event) => {
                    if (!isNaN(event.target.value)) {
                      if (isSaudiPhoneNumberInvalid(event.target.value)) {
                        setMobile(event.target.value);
                        setMobileErrMsg("Enter valid number");
                      } else {
                        setMobile(event.target.value);
                        setMobileErrMsg("");
                      }
                    }
                  }}
                />

                <div className="Add_Emp_Error__message">{mobileErrMsg ? mobileErrMsg : null}</div>
              </div>

              <div className="Add_Emp_detailinput_container_Input">
                <input
                  type="date"
                  id="datePicker"
                  className="Add_Emp_input__box"
                  placeholder="DOB"
                  value={dob}
                  max={today}
                  onChange={(event) => {
                    setDob(event.target.value);
                    setDobErrMsg("");
                  }}
                  onKeyDown={(event) => {
                    event.preventDefault();
                  }}
                />

                <div className="Add_Emp_Error__message">{dobErrMsg ? dobErrMsg : null}</div>
              </div>

              <div className={"Add_Emp_Category_Select_Container"}>
                <Select
                  placeholder="Gender"
                  options={genderOptions}
                  className={"Add_Emp_Category_Select_Option_Style"}
                  classNamePrefix="Select"
                  value={gender}
                  onChange={(event) => {
                    setGender(event);
                    setGenderErrMsg("");
                  }}
                  components={{
                    IndicatorSeparator: () => null,
                  }}
                  isSearchable={false}
                  styles={{
                    control: (baseStyles, state) => ({
                      ...baseStyles,
                      background: "#F5F5F5",
                      borderColor: state.isFocused ? "white" : null,
                      borderRadius: "25px",
                      border: state.isFocused ? 0 : 0,
                      outline: state.isFocused ? "none" : "none",
                      boxShadow: "none",
                      cursor: "pointer",
                      paddingLeft: "27px",
                      paddingRight: "27px",
                    }),
                  }}
                />
              </div>
              <div className="Add_Emp_Error__message">{genderErrMsg ? genderErrMsg : null}</div>
              {/* <div className="Add_Emp_detailinput_container_Input">
              <input
                type="text"
                className="Add_Emp_input__box"
                placeholder="Gender"
                value={gender}
                onChange={(event) => {
                  setGender(event.target.value);
                  setGenderErrMsg("");
                }}
              />
              <div className="Add_Emp_Error__message">{genderErrMsg ? genderErrMsg : null}</div>
            </div> */}
              <div className="Add_Emp_Text_Area_Input_Wrapper">
                <textarea
                  className="Add_Emp_Text_Area_Style"
                  placeholder="Description"
                  rows="8"
                  cols="50"
                  value={description}
                  onChange={(event) => {
                    setDescription(event.target.value);
                    setDescriptionErrMsg("");
                  }}
                  // onKeyDown={(event) => {
                  //   if (event.key === "Enter") {
                  //     SignUp();
                  //   }
                  // }}
                />
              </div>
              <div className="Add_Emp_Error__message">
                {descriptionErrMsg ? descriptionErrMsg : null}
              </div>
            </div>

            {/* <button
              className="Add_Emp_Save_Button"
              disabled={isLoading}
              onClick={() => {
                onClickSave();
              }}
            >
              {isLoading ? "Processing..." : "Save"}
            </button> */}

            <div className="Add_Emp_CheckBox_Main_Container">
              <input
                type="checkbox"
                className="Add_Emp_check_Box"
                checked={isActive}
                onClick={() => {
                  if (isActive) setIsActive(false);
                  else setIsActive(true);
                }}
              />
              <div className="Add_Emp_Active_Text_Style">Is active</div>
            </div>

            <div className="Add_Service_Image_container">
              {employeeImage ? (
                <img
                  src={
                    typeof employeeImage == "string"
                      ? process.env.REACT_APP_BASEURL_ONLY.concat(employeeImage)
                      : URL.createObjectURL(employeeImage)
                  }
                  alt="Image"
                  className="Empty_Prfile_Image_Style_Edit"
                />
              ) : (
                <img src={userAvatar} alt="Image" className="Empty_Prfile_Image_Style_Edit" />
              )}
              <div className="Add_Service_Icon_Container">
                <img src={Icon} alt="Image" className="Service_Cam_Image_Style" />
                <div>
                  <input
                    type="file"
                    className="popup_formupload_input"
                    accept=".png, .jpg, jpeg"
                    onChange={(e) => onSelectImage(e.target.files[0])}
                    onClick={(event) => (event.currentTarget.value = null)}
                  />
                </div>
              </div>
            </div>
          </section>

          <section className="Add_Emp_Content_second_Wrapper">
            <div>
              <div className="Add_Emp_detailinput_container_Input">
                <input
                  type="text"
                  className="Add_Emp_input__box"
                  placeholder="Salary"
                  value={salary}
                  onChange={(event) => {
                    if (!isNaN(event.target.value)) {
                      if (event.target.value.length <= 9) {
                        setSalary(event.target.value);
                        setSalaryErrMsg("");
                      }
                    }
                  }}
                />
                <div className="Add_Emp_Error__message">{salaryErrMsg ? salaryErrMsg : null}</div>
              </div>

              <div className="Add_Emp_detailinput_container_Input">
                <input
                  type="text"
                  className="Add_Emp_input__box"
                  placeholder="Commision"
                  value={commission}
                  onChange={(event) => {
                    if (!isNaN(event.target.value)) {
                      onCommissionParcentageTextChangeListener(event);
                    }
                  }}
                />
                <div className="Add_Emp_Error__message">
                  {commissionErrMsg ? commissionErrMsg : null}
                </div>
              </div>

              <div className={"Add_Emp_Category_Select_Container"}>
                <Select
                  placeholder="Services"
                  options={allServiceArr}
                  className={"Add_Emp_Category_Select_Option_Style"}
                  classNamePrefix="Select"
                  value={services}
                  onChange={(event) => {
                    setServices(event);
                    setServicesErrMsg("");
                  }}
                  components={{
                    IndicatorSeparator: () => null,
                  }}
                  isSearchable={false}
                  isMulti
                  styles={{
                    control: (baseStyles, state) => ({
                      ...baseStyles,
                      background: "#F5F5F5",
                      borderColor: state.isFocused ? "white" : null,
                      borderRadius: "25px",
                      border: state.isFocused ? 0 : 0,
                      outline: state.isFocused ? "none" : "none",
                      boxShadow: "none",
                      cursor: "pointer",
                      paddingLeft: "27px",
                      paddingRight: "27px",
                    }),
                  }}
                />
              </div>

              <div className="Add_Emp_Error__message">{servicesErrMsg ? servicesErrMsg : null}</div>
              <div className="Add_Emp_detailinput_container_Input">
                <input
                  type="password"
                  className="Add_Emp_input__box"
                  placeholder="Password"
                  value={password}
                  onChange={(event) => {
                    setPassword(event.target.value);
                    setPasswordErrMsg("");
                  }}
                />
                <div className="Add_Emp_Error__message">
                  {passwordErrMsg ? passwordErrMsg : null}
                </div>
              </div>

              <div className="Add_Emp_Text_Area_Input_Wrapper">
                <textarea
                  className="Add_Emp_Text_Area_Style"
                  placeholder="DescriptionAr"
                  rows="8"
                  cols="50"
                  value={descriptionAr}
                  onChange={(event) => {
                    setDescriptionAr(event.target.value);
                    setDescriptionArErrMsg("");
                  }}
                  // onKeyDown={(event) => {
                  //   if (event.key === "Enter") {
                  //     SignUp();
                  //   }
                  // }}
                />
              </div>
              <div className="Add_Emp_Error__message">
                {descriptionArErrMsg ? descriptionArErrMsg : null}
              </div>

              <div className="Add_Emp_CheckBox_Main_Container">
                <input
                  type="checkbox"
                  className="Add_Emp_check_Box"
                  checked={currentAvailability == 1}
                  onClick={() => {
                    currentAvailability == 1
                      ? setCurrentAvailability(0)
                      : setCurrentAvailability(1);
                  }}
                />

                <div className="Add_Emp_Active_Text_Style">Is available</div>
              </div>
            </div>
          </section>
        </div>

        <div className="Add_Emp_Schedule_Main_Container">
          <div className="Add_Emp_Schedule_Top">
            <h3>Schedule</h3>

            <button
              className="Add_Emp_Save_Button Toggle_Add_Emp_Schedule_Button"
              disabled={isLoading}
              onClick={() => {
                showAddShift ? resetShiftForm() : showShiftForm(false, null);
              }}
            >
              {showAddShift ? "Discard changes" : "Add new shift"}
            </button>
          </div>

          <div className={"Add_Emp_Schedule_Container"}>
            {showAddShift ? (
              <div className="Add_Emp_Schedule_NewEntry">
                <div className="Add_Emp_Error__message" style={{ position: "relative" }}>
                  {shiftExistsErrMsg ? shiftExistsErrMsg : null}
                </div>

                <div>
                  <label htmlFor="">Day</label>

                  <Select
                    placeholder="Please select"
                    options={daysOptions}
                    className={"Add_Emp_Category_Select_Option_Style"}
                    classNamePrefix="Select"
                    value={newShiftDay}
                    onChange={(event) => {
                      setNewShiftDay(event);
                      // setBranchesErrMsg("");
                    }}
                    components={{
                      IndicatorSeparator: () => null,
                    }}
                    isSearchable={false}
                    styles={{
                      control: (baseStyles, state) => ({
                        ...baseStyles,
                        background: "white",
                        borderColor: state.isFocused ? "white" : null,
                        borderRadius: "25px",
                        border: state.isFocused ? 0 : 0,
                        outline: state.isFocused ? "none" : "none",
                        boxShadow: "none",
                        cursor: "pointer",
                        paddingLeft: "27px",
                        paddingRight: "27px",
                      }),
                    }}
                  />
                </div>

                <div>
                  <label htmlFor="">Start time</label>
                  <input
                    type="time"
                    className="Add_Branch_input__box"
                    value={newShiftStartTime}
                    placeholder="Start time"
                    onChange={(event) => {
                      setNewShiftStartTime(event.target.value);
                      setShiftExistsErrMsg("");
                      // setOpenTimeErrMsg("");
                    }}
                  />
                </div>

                <div>
                  <label htmlFor="">End time</label>
                  <input
                    type="time"
                    className="Add_Branch_input__box"
                    value={newShiftEndTime}
                    onChange={(event) => {
                      setNewShiftEndTime(event.target.value);
                      setShiftExistsErrMsg("");
                    }}
                  />
                </div>

                <div>
                  <label htmlFor="">Start time</label>
                  <input
                    type="time"
                    className="Add_Branch_input__box"
                    value={newShiftStartTimeSecond}
                    placeholder="Start time"
                    onChange={(event) => {
                      setNewShiftStartTimeSecond(event.target.value);
                      setShiftExistsErrMsg("");
                      // setOpenTimeErrMsg("");
                    }}
                  />
                </div>

                <div>
                  <label htmlFor="">End time</label>
                  <input
                    type="time"
                    className="Add_Branch_input__box"
                    value={newShiftEndTimeSecond}
                    onChange={(event) => {
                      setNewShiftEndTimeSecond(event.target.value);
                      setShiftExistsErrMsg("");
                    }}
                  />
                </div>

                <button
                  className="Add_Emp_Save_Button Toggle_Add_Emp_Schedule_Button"
                  onClick={() => {
                    handleSubmitShift();
                    //setShiftExistsErrMsg("");
                  }}
                >
                  {indexOfShiftToEdit != null ? "Update shift" : "Add shift"}
                </button>
              </div>
            ) : (
              <></>
            )}

            <table
              className="Add_Emp_Schedule_Table"
              style={{
                borderCollapse: "separate",
                borderSpacing: "0 10px",
              }}
            >
              <thead>
                <tr>
                  <th className="main_Service_contenttableheader_entry">Day</th>
                  <th className="main_Service_contenttableheader_entry">Start time</th>
                  <th className="main_Service_contenttableheader_entry">End time</th>
                  <th className="main_Service_contenttableheader_entry">Start time</th>
                  <th className="main_Service_contenttableheader_entry">End time</th>
                  <th className="main_Service_contenttableheader_entry"></th>
                </tr>
              </thead>

              {!isLoading && schedule != null ? (
                <tbody>
                  {schedule.map((item, index) => (
                    <tr>
                      <td className="main_Emp_contenttablelist_entry">
                        {!isNullOrEmpty(item)
                          ? daysOptions.find((e) => e.value === item.day).label
                          : "Not Specified"}
                      </td>

                      <td className="main_Emp_contenttablelist_entry">
                        {!isNullOrEmpty(item)
                          ? item.start.substring(dateEndIndex)
                          : "Not Specified"}
                      </td>

                      <td className="main_Emp_contenttablelist_entry">
                        {!isNullOrEmpty(item) ? item.end.substring(dateEndIndex) : "Not Specified"}
                      </td>

                      <td className="main_Emp_contenttablelist_entry">
                        {!isNullOrEmpty(item) && item.start_1
                          ? item.start_1.substring(dateEndIndex)
                          : "Not Specified"}
                      </td>

                      <td className="main_Emp_contenttablelist_entry">
                        {!isNullOrEmpty(item) && item.end_1
                          ? item.end_1.substring(dateEndIndex)
                          : "Not Specified"}
                      </td>

                      <td>
                        <div
                          className="Add_Emp_Schedule_Table_Icon_Wrapper"
                          onClick={() => {
                            showShiftForm(true, item, index);
                          }}
                        >
                          <img
                            src={Edit}
                            alt="Image"
                            className="Add_Emp_Schedule_Table_icon_Style"
                          />
                        </div>

                        <div
                          className="Add_Emp_Schedule_Table_Icon_Wrapper"
                          onClick={() => {
                            removeShift(index);
                          }}
                        >
                          <img
                            src={Delete}
                            alt="Image"
                            className="Add_Emp_Schedule_Table_icon_Style"
                          />
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              ) : null}
            </table>
          </div>
        </div>

        <button
          className="Add_Emp_Save_Button"
          disabled={isLoading}
          onClick={() => {
            onClickSave();
          }}
        >
          {isLoading ? "Processing..." : "Save all changes"}
        </button>
      </div>
    </>
  );
}
