import "./accordian.css";
import clsx from "clsx";
import { useEffect, useState } from "react";

export default function SettingsAccordian({
  label,
  children,
  isSpecial,
  style,
  labelColor = null,
  closeAccordian,
  showArrow = true,
  time,
  price,
  onPress,
}) {
  let [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (closeAccordian) setIsOpen(false);
  }, [closeAccordian]);

  return (
    <div
      style={{ borderTop: isSpecial && "1em solid #2b3738", ...style }}
      className={clsx(
        "settings__container__main__content__accordian",
        isOpen && "settings__container__main__content__accordian__active"
      )}
      onClick={(e) => {
        e.stopPropagation();
        e.preventDefault();
        // if (!showArrow) onPress();
        onPress();
      }}
    >
      <div
        className={clsx(
          "settings__container__main__content__accordian__button",
          isOpen && "settings__container__main__content__accordian__button__active"
        )}
      >
        <div className="settings__container__main__content__accordian__button__left">
          {!showArrow ? (
            <span style={{ color: labelColor }}>{`${label}`}</span>
          ) : (
            <span style={{ color: labelColor }}>{`${label} ${
              time ? " | " + time + " minutes" : ""
            } | SAR ${price} `}</span>
          )}
        </div>

        {showArrow ? (
          <div
            className="settings__container__main__content__accordian__button__right"
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
              if (showArrow) setIsOpen((isOpen = !isOpen));
            }}
          >
            <svg
              width="11"
              height="12"
              viewBox="0 0 11 12"
              fill="white"
              style={{
                transform: !isOpen && "rotate(270deg)",
              }}
              xmlns="http://www.w3.org/2000/svg"
            >
              <mask
                id="mask0_3541_190851"
                style={{
                  maskType: "alpha",
                }}
                maskUnits="userSpaceOnUse"
                x="0"
                y="0"
                width="11"
                height="12"
              >
                <path
                  d="M4.98252 8.15869L1.68511 4.39022C1.29615 3.9457 1.61184 3.25 2.20251 3.25L8.79733 3.25C9.388 3.25 9.70369 3.9457 9.31473 4.39022L6.01732 8.15869C5.74341 8.47173 5.25643 8.47173 4.98252 8.15869Z"
                  fill="white"
                />
              </mask>
              <g mask="url(#mask0_3541_190851)">
                <rect y="0.5" width="11" height="11" fill="white" />
              </g>
            </svg>
          </div>
        ) : (
          <div className="settings__container__main__content__accordian__content__list__right__panel">
            <div className="settings__container__main__content__accordian__content__list__entry">
              {time ? time + " minutes" : ""}
            </div>
            <div
              className="settings__container__main__content__accordian__content__list__entry"
              style={{ fontWeight: "bold" }}
            >
              SAR {price}
            </div>
          </div>
        )}
      </div>
      {isOpen && (
        <div className="settings__container__main__content__accordian__content">{children}</div>
      )}
    </div>
  );
}
