import { useEffect, useState } from "react";
import "./Transactions.css";
import Select from "react-select";
import {
  getAllAvailableCustomers,
  getAllUserCall,
  getTransactionReportCall,
} from "../../api/apicalls";
import moment from "moment";
import Pagination from "react-js-pagination";
import { PuffLoader } from "react-spinners";
import { AsyncPaginate } from "react-select-async-paginate";
import { isOnlyNumber } from "../../Utils/isOnlyNumber";

export default function Transactions() {
  let [state, setState] = useState({
    startDate: "",
    endDate: "",
    customers: [],
    selectedCustomer: null,
    customerPhoneNumber: "",
    transactions: [],
    page: 1,
    limit: 20,
    totalRecords: 0,
  });
  const [isLoading, setIsLoading] = useState(false);
  let branchId = localStorage.getItem("branchId");

  useEffect(() => {
    getTransactions();
  }, [state.page]);

  function getTransactions() {
    setIsLoading(true);
    getTransactionReportCall(
      0, //state.selectedCustomer ? state.selectedCustomer.id : 0,
      branchId,
      state.startDate ? moment(state.startDate).format("YYYY-MM-DDT00:00:00") : null,
      state.endDate ? moment(state.endDate).format("YYYY-MM-DDT00:00:00") : null,
      state.selectedCustomer ? state.selectedCustomer.phone_number : "",
      state.page,
      state.limit
    )
      .then(({ data }) => {
        setIsLoading(false);
        if (data.error_code == 0) {
          setState((prevState) => ({
            ...prevState,
            transactions: data.result,
            totalRecords: data.total_records,
          }));
        } else {
          setState((prevState) => ({
            ...prevState,
            transactions: [],
            totalRecords: 0,
          }));
        }
      })
      .catch((err) => {
        console.log("err", err);
        setIsLoading(false);
      });
  }

  const loadOptions = async (searchQuery, loadedOptions, { page }) => {
    try {
      let res = await getAllAvailableCustomers(0, 50, page, searchQuery);
      if (res.data.data.getAllUserExistingCustomer.error_code == 0) {
        for (
          let index = 0;
          index < res.data.data.getAllUserExistingCustomer.result.length;
          index++
        ) {
          const element = res.data.data.getAllUserExistingCustomer.result[index];
          element.value = element.id;
          element.label = `${element.name} - ${element.phone_number}`;
        }
        return {
          options: res.data.data.getAllUserExistingCustomer.result || [],
          hasMore: Math.ceil(res.data.data.getAllUserExistingCustomer.total_records / 10) > page,
          additional: {
            page: page + 1,
          },
        };
      } else
        return {
          options: [],
          hasMore: false,
        };
    } catch (error) {
      console.error("Error loading options:", error);
      return {
        options: [],
        hasMore: false,
      };
    }
  };

  const handlePageChange = (value) => {
    setState((prevState) => ({ ...prevState, page: value }));
  };

  return (
    <>
      <div className="cover_image">
        <p className="reception_heading">Transactions</p>
      </div>
      <div className="transactions_container_wrapper">
        <div className="transactions_container">
          <div className="transactions_container__filter__section">
            <div className="transactions_container__filter__section__container">
              <input
                className="transactions_container__filter__section__container__date"
                type="date"
                placeholder="Start Date"
                value={state.startDate}
                onChange={(e) => {
                  setState((prevState) => ({
                    ...prevState,
                    startDate: e.target.value,
                    endDate: "",
                  }));
                }}
              />
            </div>
            <div className="transactions_container__filter__section__container">
              <input
                className="transactions_container__filter__section__container__date"
                type="date"
                placeholder="End Date"
                min={moment(state.startDate).format("YYYY-MM-DD")}
                value={state.endDate}
                onChange={(e) => {
                  setState((prevState) => ({ ...prevState, endDate: e.target.value }));
                }}
              />
            </div>
            <div className="transactions_container__filter__section__container">
              <AsyncPaginate
                id="companyId"
                loadOptions={loadOptions}
                value={state.selectedCustomer}
                getOptionValue={(option) => option.value}
                getOptionLabel={(option) => option.label}
                onChange={(value) =>
                  setState((prevState) => ({ ...prevState, selectedCustomer: value }))
                }
                additional={{
                  page: 1,
                }}
                isSearchable={true}
                placeholder="Customer"
                debounceTimeout={500}
                // className="book_app_customer_input"
              />
            </div>

            {/* <div className="transactions_container__filter__section__container">
              <input
                className="transactions_container__filter__section__container__date"
                type="text"
                placeholder="Enter Phone Number"
                maxLength={12}
                value={state.customerPhoneNumber}
                onChange={(e) => {
                  if (e.target.value == "" || isOnlyNumber(e.target.value))
                    setState((prevState) => ({
                      ...prevState,
                      customerPhoneNumber: e.target.value,
                    }));
                }}
              />
            </div> */}

            <div
              className="transactions_container__filter__section__container"
              style={{ minWidth: "130px" }}
            >
              <button
                className="navbar_join_btn"
                style={{ marginRight: "0em" }}
                onClick={() => {
                  if (state.endDate && !state.startDate)
                    alert("Please select start date before end date.");
                  else if (state.page > 1) setState((prevState) => ({ ...prevState, page: 1 }));
                  else getTransactions();
                }}
              >
                Apply Filter
              </button>
            </div>

            <div className="transactions_container__filter__section__container">
              <button
                className="navbar_join_btn"
                onClick={() => {
                  setState((prevState) => ({
                    ...prevState,
                    startDate: (state.startDate = ""),
                    endDate: (state.endDate = ""),
                    selectedCustomer: (state.selectedCustomer = null),
                    customerPhoneNumber: (state.customerPhoneNumber = ""),
                  }));
                  if (state.page > 1)
                    setState((prevState) => ({
                      ...prevState,
                      page: (state.page = 1),
                    }));
                  else
                    setTimeout(() => {
                      getTransactions();
                    }, 500);
                }}
              >
                Reset
              </button>
            </div>
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
              backgroundColor: "white",
              borderRadius: "20px",
              justifyContent: "center",
            }}
          >
            <table className="transactions__table">
              <thead>
                <tr>
                  <th>Date</th>
                  <th>Customer Name</th>
                  <th>Services</th>
                  <th>Employee Names</th>
                  <th>Price</th>
                  <th>Discount</th>
                  <th>Vat</th>
                  <th>Net Price</th>
                </tr>
              </thead>
              <tbody style={{ borderRadius: "0px" }}>
                {state.transactions.map((row, index) => (
                  <>
                    <tr key={index}>
                      <td>{moment(row.transcation_date?.split(".")[0]).format("YYYY-MM-DD")}</td>
                      <td>{row.customer_name}</td>
                      <td>{row.service_details.map((item) => item.service_name).join(", ")}</td>
                      <td>{row.service_details.map((item) => item.employee_name).join(", ")}</td>
                      <td>{row.price}</td>
                      <td>{row.discount}</td>
                      <td>{row.vat}</td>
                      <td>{row.net_price}</td>
                    </tr>
                  </>
                ))}
              </tbody>
            </table>

            {state.transactions.length > 0 ? (
              <div className="records__container__pagination">
                <Pagination
                  activePage={state.page}
                  itemsCountPerPage={state.limit}
                  totalItemsCount={state.totalRecords}
                  pageRangeDisplayed={5}
                  onChange={handlePageChange}
                />
              </div>
            ) : null}
          </div>
        </div>
      </div>

      {isLoading && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <PuffLoader
            loading={isLoading}
            size={150}
            aria-label="Loading Spinner"
            data-testid="loader"
          />
        </div>
      )}
    </>
  );
}
