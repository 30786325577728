import axios from "axios";

export async function LoginApiCall(login) {
  let route = process.env.REACT_APP_BASEURL;
  const graphqlQuery = {
    query: `
        mutation($login: LoginVMInput!){
            login(model: $login){
              error_code,
              message,
              result {
                id,
                phone_number,
                user_role,
               employees{
                  first_name,
                  last_name,
                }
                name,
                branch {
                  id,
                  name,
                  opening_time,
                  closing_time,
                  slot_interval,
                  type,
                }
              }
            }
          }
    `,
    variables: login,
  };
  // let response = null;
  return axios({
    url: process.env.REACT_APP_BASEURL,
    // url: route,
    method: "POST",
    data: graphqlQuery,
  });
}

export async function GetBookingsApiCall(bookings) {
  let route = process.env.REACT_APP_BASEURL;
  const graphqlQuery = {
    query: `
        query($getbookings: GetBookingsVMInput!){
            getBookings (model: $getbookings) {
              error_code,
              message,
              total_records,
              page,
              result {
                id,
                created_on,
                customer_name,
                customer_phone,
                customer_image,
                payment_status_id,
                net_price,
                booking_type_id,
                vat,
                discount,
                price,
                status_id,
                user_id,
                discount_code,
                payment_type_id,
                latitude,
                longitude,
                address,
                branch_id,
                qr_string,
                booking_transcations {
                  id,
                  booking_id,
                  payment_type_id,
                  price
                },
                booking_details{
                  id,
                  service_name,
                  service_id,
                  employee_name,
                  employee_user_id,
                  booking_id,
                  start_datetime,
                  end_datetime,
                  price
                }
              }
            }
          }
    `,
    variables: bookings,
  };
  // let response = null;
  return axios({
    url: process.env.REACT_APP_BASEURL,
    // url: route,
    method: "POST",
    data: graphqlQuery,
  });
}

export const getAllAvailableEmployees = (data) => {
  const graphqlQuery = {
    query: `query($getAvailableEmployeesModel: FreeEmployeesVMInput!){
      getFreeEmployeesOptimized(model: $getAvailableEmployeesModel){
      error_code,
      success,
      status,
      message,
      total_records,
      page,
      result {
        id,
        user_role,
        name,
        image,
        rating,
        employees{
          id,
          first_name,
          last_name,
          rating,
          description,
          image,
          user_id
        }
      }
      }
    }`,
    variables: data,
  };
  let response = null;
  return (response = axios({
    url: process.env.REACT_APP_BASEURL,
    method: "POST",
    data: graphqlQuery,
  }));
};

export const getAllCategoriesByBrancheId = (page, limit, branchId) => {
  const graphqlQuery = {
    query: `query{
      getAllCategorys(branchId: ${branchId},limit:${limit},page: ${page}) {
        error_code,
        success,
        status,
        message,
        total_records,
        result {
          id,
          name,
          name_ar,
          image,
          description,
          description_ar, 
        }
      }
    }
    `,
  };
  let response = null;
  return (response = axios({
    url: process.env.REACT_APP_BASEURL,
    method: "POST",
    data: graphqlQuery,
  }));
};

export const getAllAvailableServices = (branchId, categoryId, page, limit) => {
  const graphqlQuery = {
    query: `query{
      getAllServices(branchId: ${branchId},categoryId: ${categoryId},page: ${page},limit: ${limit}) {
        error_code,
        message,
        result {
          action_type,
          branch_id,
          category_id,
          description,
          description_ar,
          executive_price,
          price,
          id,
          image,
          name,
          name_ar,
          time,
          subservices{
            parent_id,
            id,
            name,
            name_ar,
            name_ar,
            time,
            price
          }
        }
      }
  }
    `,
  };
  let response = null;
  return (response = axios({
    url: process.env.REACT_APP_BASEURL,
    method: "POST",
    data: graphqlQuery,
  }));
};

export const addNewBooking = (bookingObj) => {
  const graphqlQuery = {
    query: `mutation($bookingModel : BookingVMInput!){
      postBooking(model: $bookingModel) {
        error_code,
        success,
        message,
      }
    }
    `,
    variables: bookingObj,
  };
  let response = null;
  return (response = axios({
    url: process.env.REACT_APP_BASEURL,
    method: "POST",
    data: graphqlQuery,
  }));
};

export const modifyBooking = (bookingObj) => {
  let route = process.env.REACT_APP_BASEURL_ONLY.concat("api/booking-detail/modify-booking")
  return axios.post(route, bookingObj)
};

export const getAllAvailableCustomers = (branchId, limit, page, search) => {
  const graphqlQuery = {
    query: `query{
      getAllUserExistingCustomer(branchId: ${branchId},limit:${limit}, page: ${page}, ${search ? `search: "${search}"` : ""}  ){
     error_code
    success
    status
    message
    total_records
    result {
      id
      user_role
      phone_number
      name
    }
      }
    }`,
  };
  let response = null;
  return (response = axios({
    url: process.env.REACT_APP_BASEURL,
    method: "POST",
    data: graphqlQuery,
  }));
};

// export const getAllAvailableCustomersNew = (branchId, limit, page, search) => {
//   let params = {}
//   if (branchId) params.branchId = branchId;
//   if (page) params.page = page;
//   if (limit) params.limit = limit;
//   if (search) params.search = search;

//   let route = process.env.REACT_APP_BASEURL_ONLY.concat("api/user/get-all");
//   return axios.get(route, {
//     params,
//     "axios-retry": {
//       retries: 5,
//     },
//   })
// };

export const validateCoupon = (branchId, coupon, userId, date) => {
  const graphqlQuery = {
    query: ` query{
      validateCoupon(branchId: ${branchId},coupon: "${coupon}", userId: ${userId}, currentDateTime: "${date}" ) {
        error_code,
        message,
        success,
        result {
          amount,
          type,
        }
      }
      }`,
  };
  let response = null;
  return (response = axios({
    url: process.env.REACT_APP_BASEURL,
    method: "POST",
    data: graphqlQuery,
  }));
};


export const getAllEmployees = (branchId, serviceId, page, limit, currentDate) => {
  const graphqlQuery = {
    query: `query{
    getAllEmployees(branchId: ${branchId},serviceId: ${serviceId},page: ${page},limit: ${limit}, currentDate:${currentDate}) {
      error_code,
      message,
      result {
        action_type,
        id,
        rating
        employees{
          id,
        branch_id,
        commission_pct,
        description,
        description_ar,
        first_name,
        first_name_ar,
        gender,
        last_name,
        last_name_ar,
        salary,
        status,
        user_id,
        image,
        rating
        }
      }
    }
  }
    `,
  };
  let response = null;
  return (response = axios({
    url: process.env.REACT_APP_BASEURL,
    method: "POST",
    data: graphqlQuery,
  }));
};

export const getEmployeeFreeSlots = (date, empId, serviceTime) => {
  const graphqlQuery = {
    query: `query{
      getSlots(dateTime:${JSON.stringify(date)},employeeId: ${empId},serviceTime: ${serviceTime}) {
        error_code,
        message,
        result {
          start_time,
          end_time,
          is_available
        }
      }
    }
    `,
  };
  let response = null;
  return (response = axios({
    url: process.env.REACT_APP_BASEURL,
    method: "POST",
    data: graphqlQuery,
  }));
};

export const changeBookingStatus = (bookingObj) => {
  const graphqlQuery = {
    query: `mutation($paymentModel : ChangeBookingStatusVMInput!){
      changeBookingStatus(model: $paymentModel) {
        error_code,
        success,
        message,
        result {
          qr_string
        }
      }
    }
    `,
    variables: bookingObj,
  };
  let response = null;
  return (response = axios({
    url: process.env.REACT_APP_BASEURL,
    method: "POST",
    data: graphqlQuery,
  }));
};

export async function getAllEmployeeSchedule(object) {
  let route = process.env.REACT_APP_BASEURL;
  const graphqlQuery = {
    query: `
  query($Model : AllEmployeesScheduleVMInput!){
    getAllEmployeeSchedule(model: $Model) {
      error_code,
      message,
      result {
        name,
        is_free
      }
    }
  }
`,
    variables: object,
  };
  let response = null;
  return (response = axios({
    url: process.env.REACT_APP_BASEURL,
    method: "POST",
    data: graphqlQuery,
  }));
}

export const getVatRateById = (id) => {
  const params = {};
  if (id) {
    params.id = id;
  }
  return axios.get(
    process.env.REACT_APP_BASEURL_ONLY + "api/organization/get",
    {
      params,
      headers: {
        "Content-Type": "application/json",
      },
      "axios-retry": {
        retries: 5,
      },
    }
  );
};

export const customerSignup = (data) => {
  const graphqlQuery = {
    query: `
    mutation($signupModel : UserVMInput!){
        postUser(model: $signupModel) {
          error_code,
          message,
          success,
          result {
            id,
            name,
            phone_number,
            image,
            code,
            code_generated_on,
          }
        }
      }
    `,
    variables: data,
  };
  let response = null;
  return (response = axios({
    url: process.env.REACT_APP_BASEURL,
    method: "POST",
    data: graphqlQuery,
  }));
};

export const verifyCode = (data) => {
  const graphqlQuery = {
    query: `mutation($verifyCodeModel : VerifyCodeVMInput!){
      verifyCode(model: $verifyCodeModel ) {
        error_code,
        message,
        success,
        result {
          id,
          image,
          name,
          phone_number,
      
        }
      }
    }
    `,
    variables: data,
  };
  let response = null;
  return (response = axios({
    url: process.env.REACT_APP_BASEURL,
    method: "POST",
    data: graphqlQuery,
  }));
};

export async function checkNextIntervalOfCurrentBooking(obj) {
  const graphqlQuery = {
    query: `
query($model: CheckEmployeeVMInput!)
{
  checkFreeEmployee (model : $model){
    error_code,
    success,
    message,
  }
}
  `,
    variables: obj,
  };
  let response = null;
  return (response = axios({
    url: process.env.REACT_APP_BASEURL,
    method: "POST",
    data: graphqlQuery,
  }));
}

export const deleteBooking = (data) => {
  const graphqlQuery = {
    query: `mutation($ID : IdViewModelInput!){
      deleteBooking(model: $ID ) {
        error_code,
        message,
        success,
      }
    }
    `,
    variables: data,
  };
  let response = null;
  return (response = axios({
    url: process.env.REACT_APP_BASEURL,
    method: "POST",
    data: graphqlQuery,
  }));
};

export const cancelBookingCall = async (data) => {
  let route = process.env.REACT_APP_BASEURL_ONLY.concat("api/booking/cancel-booking");
  return axios.post(route, data, {
    "axios-retry": {
      retries: 5,
    },
  })
};

export const getTransactionReportCall = async (customerId, branchId, from, to, phone_number, page, limit) => {
  let params = {}
  if (customerId) params.customerId = customerId;
  if (branchId) params.branchId = branchId;
  if (from) params.from = from;
  if (to) params.to = to;
  if (phone_number) params.phone_number = phone_number;
  if (page) params.page = page;
  if (limit) params.limit = limit;

  let route = process.env.REACT_APP_BASEURL_ONLY.concat("api/dashboard/get-transcation-report");
  return axios.get(route, {
    params,
    "axios-retry": {
      retries: 5,
    },
  })
};

export const getDailyTransactionReportCall = async (receptionistId, from, to, page, limit) => {
  let params = {}
  if (receptionistId) params.receptionistId = receptionistId;
  if (from) params.from = from;
  if (to) params.to = to;
  if (page) params.page = page;
  if (limit) params.limit = limit;

  let route = process.env.REACT_APP_BASEURL_ONLY.concat("api/dashboard/get-daily-transcation-report");
  return axios.get(route, {
    params,
    "axios-retry": {
      retries: 5,
    },
  })
};

export const getAllUserCall = async (branchId, page, limit) => {
  let params = {}
  if (branchId) params.branchId = branchId;
  if (page) params.page = page;
  if (limit) params.limit = limit;

  let route = process.env.REACT_APP_BASEURL_ONLY.concat("api/user/get-all");
  return axios.get(route, {
    params,
    "axios-retry": {
      retries: 5,
    },
  })
};

export const changeBookingPaymentTypeCall = async (object) => {
  let route = process.env.REACT_APP_BASEURL_ONLY.concat("api/booking/change-bookingpayment-type");
  return axios.post(route, object, {
    "axios-retry": {
      retries: 5,
    },
  })
};

export const getAllServices = (
  limit,
  page,
  categoryId,
  search,
  is_admin,
  branchId
) => {
  const params = {};
  if (limit) params.limit = limit;
  if (page) params.page = page;
  if (categoryId) params.categoryId = categoryId;
  if (search) params.search = search;
  if (is_admin) params.is_admin = is_admin;
  if (branchId) params.branchId = branchId;
  return axios.get(
    process.env.REACT_APP_BASEURL_ONLY.concat("api/service/get-all"),
    {
      params,
      headers: {
        "Content-Type": "application/json",
      },
      "axios-retry": {
        retries: 5,
      },
    }
  );
};

export const addOrEditEmployee = (data) => {
  return axios.post(
    process.env.REACT_APP_BASEURL_ONLY + "api/employee/post",
    data,
    {
      "axios-retry": {
        retries: 5,
      },
    }
  );
};

export const addOrEditEmployeeImage = (data) => {
  return axios.post(
    process.env.REACT_APP_BASEURL_ONLY + "api/employee/change-image",
    data,
    {
      "axios-retry": {
        retries: 5,
      },
    }
  );
};