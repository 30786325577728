import { useEffect, useState } from "react";
import "./BookAppointment.css";
import "../Accordian/accordian.css";
import Step1Seleced from "../../img/book_app_step1_selected.png";
import Step2Seleced from "../../img/book_app_step2_selected.png";
import Step1UnSeleced from "../../img/book_app_step1_unselected.png";
import Step2UnSeleced from "../../img/book_app_step2_unselected.png";
import BookAppBookingsRow from "./BookAppBookingsRow/BookAppBookingsRow";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import {
  customerSignup,
  getAllAvailableCustomers,
  getAllAvailableServices,
  getAllCategoriesByBrancheId,
  getAllEmployees,
  getEmployeeFreeSlots,
  verifyCode,
} from "../../api/apicalls";
import moment from "moment";
import { isNullOrEmpty } from "../../Utils/isNullOrEmpty";
import { toast, Toaster } from "react-hot-toast";
import { CustomError } from "../Toast";
import { isOnlyNumber } from "../../Utils/isOnlyNumber";
import ScrollContainer from "react-indiana-drag-scroll";
import SettingsAccordian from "../Accordian/AccordianView";
import { GridLoader } from "react-spinners";
import EmployeeImage from "../../img/app_det_user_img.png";
import { AsyncPaginate } from "react-select-async-paginate";

const NewBookAppointment = () => {
  const navigate = useNavigate();
  const branch_id = localStorage.getItem("branchId");
  const cartProducts = localStorage.getItem("cartProducts");
  const user = localStorage.getItem("customer");
  const [iswalkin, setiswalkin] = useState(true);
  const [step, setStep] = useState("1");
  const [issearch, setissearch] = useState(false);
  const [cartArray, setCartArray] = useState(cartProducts ? JSON.parse(cartProducts) : []);
  const [phone, setPhone] = useState("");

  const [selectedCustomer, setSelectedCustomer] = useState(user ? JSON.parse(user) : "");
  const [selectedEmployee, setSelectedEmployee] = useState("");
  const [code, setCode] = useState("");
  const [customerId, setCustomerId] = useState(0);
  const [name, setName] = useState("");

  const [customerErrorMessage, setCustomerErrorMessage] = useState("");
  const [phoneErrorMessage, setPhoneErrorMessage] = useState("");
  const [codeErrorMessage, setCodeErrorMessage] = useState("");
  const [nameErrorMessage, setNameErrorMessage] = useState("");

  const [isLoading, setIsLoading] = useState(false);
  const [isEmployeeLoading, setIsEmployeeLoading] = useState(false);

  const userId = localStorage.getItem("receptionistId");

  //new states
  let [categoriesOptions, setCategoriesOptions] = useState([]);
  let [selectedCategory, setSelectedCategory] = useState(null);
  const [services, setServices] = useState([]);
  const [selectedService, setSelectedService] = useState(null);
  const [employees, setEmployees] = useState([]);
  const [dateItems, setDateItems] = useState([]);
  const [selectedDate, setSelectedDate] = useState(null);
  const [timeItems, setTimeItems] = useState([]);
  const [selectedTime, setSelectedTime] = useState(null);

  useEffect(() => {
    if (!userId) navigate("/");
  }, []);

  //   useEffect(() => {
  //     setOpeningTime((openingTime = opening_time));
  //     setClosingTime((closingTime = closing_time));
  //   }, [selectedDate]);

  useEffect(() => {
    // getAllCustomer();
    getAllBranchCategories();
  }, []);

  useEffect(() => {
    if (selectedCategory) getAllServices();
  }, [selectedCategory]);

  useEffect(() => {
    if (selectedService) {
      getDates();
      getEmployees();
    }
  }, [selectedService]);

  useEffect(() => {
    if (selectedEmployee && selectedDate) getSlots();
  }, [selectedEmployee, selectedDate]);

  // const getAllCustomer = () => {
  //   getAllAvailableCustomers(0, 10000, 1)
  //     .then((res) => {
  //       let newArray = [];
  //       if (res.data.data.getAllUsers.error_code === 0) {
  //         for (let index = 0; index < res.data.data.getAllUsers.result.length; index++) {
  //           const element = res.data.data.getAllUsers.result[index];
  //           element.value = element.id;
  //           element.label = element.name + " - " + element.phone_number;
  //           newArray.push(element);
  //         }
  //         setCustomers(newArray);
  //       } else setCustomers([]);
  //     })
  //     .catch((err) => {
  //       console.log("err", err);
  //     });
  // };

  const loadOptions = async (searchQuery, loadedOptions, { page }) => {
    try {
      let res = await getAllAvailableCustomers(0, 50, page, searchQuery);
      if (res.data.data.getAllUserExistingCustomer.error_code == 0) {
        for (
          let index = 0;
          index < res.data.data.getAllUserExistingCustomer.result.length;
          index++
        ) {
          const element = res.data.data.getAllUserExistingCustomer.result[index];
          element.value = element.id;
          element.label = element.name + " - " + element.phone_number;
        }
        return {
          options: res.data.data.getAllUserExistingCustomer.result || [],
          hasMore: Math.ceil(res.data.data.getAllUserExistingCustomer.total_records / 10) > page,
          additional: {
            page: page + 1,
          },
        };
      } else
        return {
          options: [],
          hasMore: false,
        };
    } catch (error) {
      return {
        options: [],
        hasMore: false,
      };
    }
  };

  const getAllBranchCategories = () => {
    getAllCategoriesByBrancheId(1, 1000, branch_id)
      .then(({ data }) => {
        if (data.data.getAllCategorys.error_code == 0) {
          setCategoriesOptions((categoriesOptions = data.data.getAllCategorys.result));
          setSelectedCategory((selectedCategory = categoriesOptions[0]));
          // getAllBranchServicesByCategory(categoriesOptions[0].value);
        } else if (data.data.getAllCategorys.error_code == 4) {
          setCategoriesOptions((categoriesOptions = []));
          setSelectedCategory((selectedCategory = ""));
        }
      })
      .catch((err) => {
        console.log("err", err);
        setCategoriesOptions((categoriesOptions = []));
        setSelectedCategory((selectedCategory = ""));
      });
  };

  const getAllServices = () => {
    getAllAvailableServices(branch_id, selectedCategory.id, 1, 1000)
      .then(({ data }) => {
        if (data.data.getAllServices.error_code === 0) setServices(data.data.getAllServices.result);
        else setServices([]);
      })
      .catch((err) => {
        console.log("err", err);
        setServices([]);
      });
  };

  const getEmployees = () => {
    const todayDate = new Date();
    const utcDate = todayDate.toUTCString();
    let formatted_TodayDate = moment(utcDate).format("YYYY-MM-DDTHH:mm:ss");
    setIsEmployeeLoading(true);
    getAllEmployees(
      branch_id,
      selectedService.subservices ? selectedService.subservices.id : selectedService.id,
      1,
      1000,
      JSON.stringify(formatted_TodayDate)
    )
      .then(({ data }) => {
        setIsEmployeeLoading(false);
        if (data.data.getAllEmployees.error_code === 0)
          setEmployees(data.data.getAllEmployees.result);
        else setEmployees([]);
      })
      .catch((err) => {
        setIsEmployeeLoading(false);
        console.log("err", err);
      });
  };

  function getDayOfWeek(day) {
    const daysOfWeek = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
    return daysOfWeek[day];
  }

  function getDates() {
    const startDate = new Date();
    const endDate = new Date(startDate.setDate(startDate.getDate() + 7));
    let newDatesArray = [];
    var currentDate = new Date();
    while (currentDate <= endDate) {
      let object = {
        dayName: getDayOfWeek(currentDate.getDay()),
        formattedDate: new Date(currentDate),
      };
      newDatesArray.push(object);
      currentDate = new Date(currentDate.setDate(currentDate.getDate() + 1));
    }
    setDateItems(newDatesArray);
  }

  function getSlots() {
    let currentDate = moment().format("YYYY-MM-DD");
    getEmployeeFreeSlots(
      moment(selectedDate).format("YYYY-MM-DD") > currentDate
        ? moment(selectedDate).format("YYYY-MM-DD 00:00:00.000+00:00")
        : moment(selectedDate).format("YYYY-MM-DD HH:mm:ss.000+00:00"),
      selectedEmployee?.employees[0].id,
      selectedService?.subservices ? selectedService?.subservices.time : selectedService?.time
    )
      .then(({ data }) => {
        let service = selectedService;
        let serviceId = service.subservices ? service.subservices.id : service.id;
        if (data.data.getSlots.error_code == 0) {
          let newArray = [];
          for (let index = 0; index < data.data.getSlots.result.length; index++) {
            const element = data.data.getSlots.result[index];
            element.start_time = element.start_time.substring(0, 19);
            element.end_time = element.end_time.substring(0, 19);

            element.value = element.start_time;
            element.label = moment(element.start_time).format("HH:mm");

            let findObject = cartArray.find(
              (x) =>
                x.employee_user_id == selectedEmployee.id &&
                x.service_id == serviceId &&
                moment(x.start_datetime).format("YYYY-MM-DD") ==
                  moment(selectedDate).format("YYYY-MM-DD") &&
                moment(x.start_datetime).format("HH:mm") <=
                  moment(element.start_time).format("HH:mm") &&
                moment(element.end_time).format("HH:mm") <=
                  moment(x.start_datetime).add("minutes", service.time).format("HH:mm")
            );
            if (findObject) {
              if (
                !(
                  moment(findObject.start_datetime).format("HH:mm") <=
                    moment(element.start_time).format("HH:mm") &&
                  moment(element.end_time).format("HH:mm") <=
                    moment(findObject.start_datetime).add("minutes", service.time).format("HH:mm")
                )
              )
                newArray.push(element);
            } else newArray.push(element);
          }
          setTimeItems(newArray);
        } else {
          setTimeItems([]);
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
  }

  const isStep1Valid = () => {
    if (iswalkin) {
      if (isNullOrEmpty(name)) setNameErrorMessage("Please enter name");
      else if (isNullOrEmpty(phone)) setPhoneErrorMessage("Please enter phone number");
      else if (!customerId) CustomError("Please register user first");
      else if (isNullOrEmpty(selectedService)) CustomError("Please select service");
      else return true;
      return false;
    } else {
      if (isNullOrEmpty(selectedCustomer)) setCustomerErrorMessage("Please select customer");
      else if (isNullOrEmpty(selectedService)) CustomError("Please select service");
      else return true;
      return false;
    }
  };

  const onAddMoreClick = () => {
    if (selectedEmployee && selectedDate && selectedTime) {
      if (iswalkin && !customerId) {
        CustomError("Please register an employee then continue.");
      } else {
        debugger;
        let isBetweenDates = cartArray.find((x) => {
          const selectedStartTime = moment(selectedTime.start_time);
          // const selectedEndTime = moment(state.selectedTime.end_time);
          const selectedEndTime = moment(selectedTime.start_time).add(
            "minutes",
            selectedService?.subservices ? selectedService?.subservices.time : selectedService?.time
          );
          const startTime = moment(x.start_datetime);
          const endTime = moment(x.end_datetime);
          // const endTime = moment(x.start_datetime).add("minutes", x.serviceInterval);
          if (
            (startTime >= selectedStartTime && startTime < selectedEndTime) ||
            (endTime > selectedStartTime && endTime <= selectedEndTime) ||
            (startTime <= selectedStartTime && endTime >= selectedEndTime)
          ) {
            // if (x.employee_user_id == state.selectedEmployee.id) return x;
            return x;
          }
        });
        debugger;
        if (!isBetweenDates) {
          var newArray = [...cartArray];
          let cartObject = {
            id: 0,
            booking_id: 0,
            employee_user_id: selectedEmployee?.employees[0].user_id,
            employee_name: selectedEmployee?.employees[0].first_name,
            service_id: selectedService?.subservices
              ? selectedService?.subservices.id
              : selectedService?.id,
            // service_name: selectedService.name,
            service_name: selectedService?.subservices
              ? selectedService?.subservices.name
              : selectedService?.name,
            customer_user_id: iswalkin ? customerId : parseInt(selectedCustomer.id),
            price: selectedService?.subservices
              ? selectedService?.subservices.price
              : selectedService?.price,
            is_executive: 0,
            start_datetime: selectedTime?.start_time,
            // end_datetime: selectedTime?.end_time,
            end_datetime: moment(selectedTime?.start_time)
              .add(
                "minutes",
                selectedService?.subservices
                  ? selectedService?.subservices.time
                  : selectedService?.time
              )
              .format("YYYY-MM-DDTHH:mm:00"),
            rating: selectedEmployee?.employees[0].rating,
            status_id: 3,
            is_disabled: false,
            intervalTime: selectedService?.subservices
              ? selectedService?.subservices.time
              : selectedService?.time,
            serviceInterval: selectedService?.subservices
              ? selectedService?.subservices.time
              : selectedService?.time,
          };
          newArray.push(cartObject);
          setCartArray(newArray);
          localStorage.setItem("customer", JSON.stringify(selectedCustomer));
          localStorage.setItem("cartProducts", JSON.stringify(newArray));
          setSelectedService(null);
          setSelectedEmployee(null);
          setSelectedDate(null);
          setSelectedTime(null);
          setStep("1");
        } else CustomError("This slot is already booked. Please try another slot.");
      }
    }
  };

  const onDeleteClick = (item, index) => {
    let new_cart_data = [...cartArray];
    new_cart_data = new_cart_data.slice(0, index).concat(new_cart_data.slice(index + 1));
    setCartArray(new_cart_data);
    if (new_cart_data.length > 0)
      localStorage.setItem("cartProducts", JSON.stringify(new_cart_data));
    else {
      localStorage.removeItem("cartProducts");
      localStorage.removeItem("customer");
    }
  };

  const onCustomerSelect = (e) => {
    if (selectedCustomer) {
      if (selectedCustomer.id !== e.id) {
        localStorage.removeItem("cartProducts");
        localStorage.removeItem("customer");
        setCartArray([]);
      }
    }
  };

  const SignUp = () => {
    let PHONE_NUMBER_SAUDIA = /^(009665|9665|\+9665|05|5)(5|0|3|6|4|9|1|8|7)([0-9]{7})$/;
    if (isNullOrEmpty(name)) setNameErrorMessage("Enter Name");
    else if (isNullOrEmpty(phone)) setPhoneErrorMessage("Enter Phone Number");
    else if (!PHONE_NUMBER_SAUDIA.test(phone)) setPhoneErrorMessage("Enter Valid Phone Number");
    else {
      let requestObj = {
        signupModel: {
          id: 0,
          name: name,
          phone_number: phone,
          role_id: 1,
        },
      };
      setIsLoading(true);
      customerSignup(requestObj)
        .then((res) => {
          setIsLoading(false);
          if (res.data.data.postUser.success) {
            setCustomerId(res.data.data.postUser.result.id);
            toast("Customer Registered Successfully!");
            // getAllCustomer();
          } else {
            CustomError(res.data.data.postUser.message);
          }
        })
        .catch((err) => {
          setIsLoading(false);
          CustomError("Signup Failed");
        });
    }
  };

  const verifyOtp = () => {
    if (isNullOrEmpty(code)) setCodeErrorMessage("Enter OTP");
    else {
      let requestObj = {
        verifyCodeModel: {
          phone_number: phone,
          code: code,
        },
      };
      setIsLoading(true);
      verifyCode(requestObj)
        .then((res) => {
          setIsLoading(false);
          if (res.data.data.verifyCode.error_code == 2) {
            setCodeErrorMessage("Invalid code");
          } else if (res.data.data.verifyCode.error_code == 0) {
            setissearch(false);
            setCustomerId(res.data.data.verifyCode.result.id);
          }
        })
        .catch((err) => {
          setIsLoading(false);
        });
    }
  };

  //   const onAdd = (data, index) => {
  //     const intervalTime = data.intervalTime;

  //     let currentDay = moment(selectedDate).day() + 1;

  //     var date = data.start_datetime.split("T");
  //     var startTime = moment(data.start_datetime).add(intervalTime, "minutes").format("HH:mm");
  //     var endTime = moment(data.end_datetime).add(intervalTime, "minutes").format("HH:mm");

  //     if (endTime > closing_time) CustomError("Unable to book appointment in closing time");
  //     else {
  //       let obj = {
  //         model: {
  //           employee_user_id: data.employee_user_id,
  //           branch_id: parseInt(branch_id),
  //           service_id: data.service_id,
  //           start: date[0] + "T" + startTime + ":00+00:00",
  //           end: date[0] + "T" + endTime + ":00+00:00",
  //           day: currentDay,
  //         },
  //       };
  //       onAddClick(obj, data, date[0] + "T" + startTime, date[0] + "T" + endTime, index);
  //     }
  //   };

  // const onAddClick = (object, item, start, end, index) => {
  //   let cartObject = {
  //     id: cartArray.length + 1,
  //     booking_id: 0,
  //     employee_user_id: item.employee_user_id,
  //     employee_name: item.employee_name,
  //     service_id: item.service_id,
  //     service_name: item.service_name,
  //     customer_user_id: item.customer_user_id,
  //     price: item.price,
  //     is_executive: 0,
  //     start_datetime: start,
  //     end_datetime: end,
  //     status_id: 3,
  //     is_disabled: false,
  //     intervalTime: item.intervalTime,
  //   };

  //   setIsLoading(true);
  //   checkNextIntervalOfCurrentBooking(object)
  //     .then(async ({ data }) => {
  //       setIsLoading(false);
  //       if (data.data.checkFreeEmployee.error_code == 0) {
  //         let newData = [...cartArray];
  //         newData.push(cartObject);
  //         newData[index].is_disabled = true;
  //         // cartArray.push(cartObject);
  //         setCartArray(newData);
  //         localStorage.setItem("cartProducts", JSON.stringify(newData));
  //       } else CustomError(data.data.checkFreeEmployee.message);
  //     })
  //     .catch((err) => {
  //       setIsLoading(false);
  //       console.log("err", err);
  //       CustomError("Some error occurred, Please try again");
  //     });
  // };

  return (
    <>
      <div className="billing_summary_cover_image">
        <p className="reception_heading">Book appointment</p>
      </div>

      <div className="book_app_main_container_wrapper">
        <div className="book_app_main_container">
          <div className="book_app_customer_container" id="book_app_customer_container">
            <div className="book_app_customer_container_header">
              <div className="book_app_customer_container_heading">Fill Customer Data</div>
            </div>

            <div className="book_app_customer_buttons">
              <button
                className={
                  iswalkin
                    ? "book_app_customer_walkin_btn book_app_btn_selected"
                    : "book_app_customer_walkin_btn book_app_btn_unselected"
                }
                onClick={(event) => {
                  if (!iswalkin) {
                    setiswalkin(true);
                  }
                  setStep("1");
                  setSelectedCustomer(null);
                  setSelectedService(null);
                }}
              >
                Walk-in Customer
              </button>
              <button
                className={
                  iswalkin
                    ? "book_app_customer_existing_btn book_app_btn_unselected"
                    : "book_app_customer_existing_btn book_app_btn_selected"
                }
                onClick={(event) => {
                  if (iswalkin) {
                    setiswalkin(false);
                    setPhone("");
                    setName("");
                  }
                  setStep("1");
                  setCustomerId(0);
                  setSelectedCustomer(null);
                  setSelectedService(null);
                }}
              >
                Existing Customer
              </button>
            </div>
            {iswalkin ? (
              <>
                <input
                  type="text"
                  placeholder="Customer Name"
                  className="book_app_customer_input"
                  onChange={(e) => {
                    setName(e.currentTarget.value);
                    setNameErrorMessage("");
                  }}
                />
                <div className="error__message" style={{ textAlign: "start" }}>
                  {nameErrorMessage}
                </div>
                <input
                  type="text"
                  placeholder="Phone Number"
                  className="book_app_customer_input"
                  maxLength={12}
                  value={phone}
                  onChange={(e) => {
                    if (isNullOrEmpty(e.currentTarget.value) || isOnlyNumber(e.currentTarget.value))
                      setPhone(e.currentTarget.value);
                    setPhoneErrorMessage("");
                  }}
                  style={{ marginTop: "1em" }}
                />
                <div className="error__message" style={{ textAlign: "start" }}>
                  {phoneErrorMessage}
                </div>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <button
                    className="inv_coupon_btn"
                    onClick={() => SignUp()}
                    disabled={isLoading}
                    style={{
                      width: "50%",
                      padding: "0.5em 0",
                      marginTop: "2em",
                    }}
                  >
                    {isLoading ? "Processing..." : "Register User"}
                  </button>
                </div>
              </>
            ) : (
              <>
                <AsyncPaginate
                  id="customers"
                  loadOptions={loadOptions}
                  value={selectedCustomer}
                  getOptionValue={(option) => option.value}
                  getOptionLabel={(option) => option.label}
                  onChange={(value) => {
                    setSelectedCustomer(value);
                    onCustomerSelect(value);
                    setCustomerErrorMessage("");
                  }}
                  additional={{
                    page: 1,
                  }}
                  isSearchable={true}
                  placeholder="Name"
                  debounceTimeout={500}
                  className="book_app_customer_input"
                  theme={(theme) => ({
                    ...theme,
                    borderRadius: 0,
                    colors: {
                      ...theme.colors,
                      primary75: "#2a5e59",
                      primary25: "#2a5e595e",
                      primary50: "#2a5e595e",
                      primary: "#2a5e59",
                    },
                  })}
                  styles={{
                    control: (base, state) => ({
                      ...base,
                      // "&:hover": { borderColor: "#00AB67" }, // border style on hover
                      border: "1px solid transparent", // default border color
                      // boxShadow: "none", // no box-shadow
                      borderRadius: "20px",
                      marginRight: "0.5em",
                      background: "#f5f6fa",
                    }),
                  }}
                />
                {/* <Select
                  placeholder="Name"
                  options={customers}
                  value={selectedCustomer}
                  className="book_app_customer_input"
                  theme={(theme) => ({
                    ...theme,
                    borderRadius: 0,
                    colors: {
                      ...theme.colors,
                      primary75: "#2a5e59",
                      primary25: "#2a5e595e",
                      primary50: "#2a5e595e",
                      primary: "#2a5e59",
                    },
                  })}
                  styles={{
                    control: (base, state) => ({
                      ...base,
                      // "&:hover": { borderColor: "#00AB67" }, // border style on hover
                      border: "1px solid transparent", // default border color
                      boxShadow: "none", // no box-shadow
                      borderRadius: "20px",
                      marginRight: "0.5em",
                      background: "#f5f6fa",
                    }),
                  }}
                  onChange={(e) => {
                    setSelectedCustomer(e);
                    onCustomerSelect(e);
                    setCustomerErrorMessage("");
                  }}
                /> */}
                <div className="error__message">{customerErrorMessage}</div>
              </>
            )}

            {issearch ? (
              <div
                className="book_app_customer_search_container"
                id="book_app_customer_search_container"
              >
                <div
                  style={{
                    fontSize: "18px",
                    marginTop: "2em",
                    fontWeight: "600",
                  }}
                >
                  Verify User
                </div>

                <div className="book_app_customer_search_input_container">
                  <input
                    type="text"
                    placeholder="Enter OTP"
                    className="book_app_customer_search_input"
                    maxLength={4}
                    onChange={(e) => {
                      setCode(e.currentTarget.value);
                      setCodeErrorMessage("");
                    }}
                  />
                  <div className="error__message">{codeErrorMessage}</div>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    width: "100%",
                  }}
                >
                  <button
                    className="inv_coupon_btn"
                    onClick={() => verifyOtp()}
                    style={{
                      width: "50%",
                      padding: "0.5em 0",
                      marginTop: "2em",
                    }}
                    disabled={isLoading}
                  >
                    {isLoading ? "Processing..." : "Verify"}
                  </button>
                </div>
              </div>
            ) : (
              ""
            )}
          </div>

          <div className="book_app_service_container">
            <div className="book_app_service_heading">Select service and time</div>

            {step === "1" ? (
              <div className="book_app_service_steps_container">
                <img
                  src={Step1Seleced}
                  alt="step1"
                  onClick={() => {
                    setStep("1");
                    setSelectedEmployee(null);
                    setSelectedDate(null);
                    setSelectedTime(null);
                  }}
                />
                <img
                  src={Step2UnSeleced}
                  alt="step2"
                  onClick={() => {
                    if (isStep1Valid()) setStep("2");
                  }}
                />
                <hr className="book_app_service_steps_line" />
              </div>
            ) : null}

            {step === "2" ? (
              <div className="book_app_service_steps_container">
                <img
                  src={Step1UnSeleced}
                  alt="step1"
                  onClick={() => {
                    setStep("1");
                    setSelectedEmployee(null);
                    setSelectedDate(null);
                    setSelectedTime(null);
                  }}
                />
                <img
                  src={Step2Seleced}
                  alt="step2"
                  onClick={() => {
                    if (isStep1Valid()) setStep("2");
                  }}
                />
                <hr className="book_app_service_steps_line" />
              </div>
            ) : null}

            {step === "1" ? (
              <>
                <div
                  style={{
                    display: "flex",
                    alignSelf: "center",
                    alignItems: "center",
                    justifyContent: "center",
                    width: "100%",
                    paddingLeft: "5%",
                    paddingRight: "5%",
                    marginTop: "5%",
                  }}
                >
                  <ScrollContainer style={{ display: "flex" }}>
                    {categoriesOptions.map((item, index) => (
                      <div
                        key={index}
                        style={{
                          marginRight: "5px",
                          fontSize: "16px",
                          cursor: "pointer",
                          backgroundColor:
                            selectedCategory.id == item.id ? "#7D8035" : "transparent",
                          borderRadius: "20px",
                          padding: "0.5em 1em",
                          color: selectedCategory.id == item.id ? "white" : "black",
                        }}
                        onClick={() => {
                          setSelectedCategory(item);
                          setServices([]);
                        }}
                      >
                        {item.name}
                      </div>
                    ))}
                  </ScrollContainer>
                </div>

                <div className="Header_Service_Content_Conatiner">
                  {services.map((item, index) => (
                    <SettingsAccordian
                      label={item.name}
                      onPress={() => {
                        if (!iswalkin && !selectedCustomer)
                          CustomError("Please select customer first");
                        else if (iswalkin && !customerId)
                          CustomError("Please register user first.");
                        else {
                          let newObject = { ...item };
                          newObject.subservices = null;
                          setSelectedService(newObject);
                          setStep("2");
                        }
                      }}
                      showArrow={item.subservices.length > 0}
                      time={item.time}
                      price={item.price}
                    >
                      {item.subservices.map((subService, i) => (
                        <div
                          onClick={(e) => {
                            e.stopPropagation();
                            e.preventDefault();
                            if (!iswalkin && !selectedCustomer)
                              CustomError("Please select customer first");
                            else if (iswalkin && !customerId)
                              CustomError("Please register user first.");
                            else {
                              let newObject = { ...item };
                              newObject.subservices = subService;
                              setSelectedService(newObject);
                              setStep("2");
                            }
                          }}
                          className="settings__container__main__content__accordian__content__list"
                        >
                          <div className="settings__container__main__content__accordian__content__list__left__panel">
                            <div className="settings__container__main__content__accordian__content__list__entry">
                              {subService.name}
                            </div>
                          </div>
                          <div className="settings__container__main__content__accordian__content__list__right__panel">
                            <div className="settings__container__main__content__accordian__content__list__entry">
                              {subService.time ? subService.time + " minutes" : ""}
                            </div>
                            <div
                              className="settings__container__main__content__accordian__content__list__entry"
                              style={{ fontWeight: "bold" }}
                            >
                              SAR {subService.price}
                            </div>
                            <button
                              className={
                                "settings__container__main__content__accordian__content__list__entry__button"
                              }
                              // onClick={(e) => {
                              //   e.stopPropagation();
                              //   e.preventDefault();
                              //   if (!iswalkin && !selectedCustomer)
                              //     CustomError("Please select customer first");
                              //   else if (iswalkin && !customerId)
                              //     CustomError("Please register user first.");
                              //   else {
                              //     let newObject = { ...item };
                              //     newObject.subservices = subService;
                              //     setSelectedService(newObject);
                              //     setStep("2");
                              //   }
                              // }}
                            >
                              Select
                            </button>
                          </div>
                        </div>
                      ))}
                    </SettingsAccordian>
                  ))}
                </div>
              </>
            ) : null}

            {step === "2" ? (
              <div className="employee_screen_main_content_wrapper">
                <div className="employee_screen_main_content_wrapper__header">Select Employee</div>

                <div className="employee_screen_main_content_employees_wrapper">
                  <div className="employee_screen_main_content_employees_scroll_wrapper">
                    {employees.length > 0 ? (
                      <ScrollContainer style={{ display: "flex" }}>
                        {employees.map((item, index) => (
                          <div
                            className="employee__screen__main__content__employees__container"
                            style={{
                              backgroundColor:
                                selectedEmployee?.id == item.id ? "#7D8035" : "#f1f1f1",
                            }}
                            onClick={() => {
                              setSelectedEmployee(selectedEmployee?.id == item.id ? null : item);
                              setSelectedDate(null);
                              setSelectedTime(null);
                              setTimeItems([]);
                            }}
                          >
                            <img
                              src={
                                item.employees[0].image
                                  ? process.env.REACT_APP_BASEURL_ONLY.concat(
                                      item.employees[0].image
                                    )
                                  : EmployeeImage
                              }
                              className="employee__screen__main__content__employee__image"
                            />
                            <div className="employee__screen__main__content__employee__name">
                              {item.employees[0].first_name + " " + item.employees[0].last_name}
                            </div>
                            <div className="employee__screen__main__content__employee__rating__container">
                              <svg
                                width="18"
                                height="17"
                                viewBox="0 0 18 17"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                                style={{ marginRight: "5px" }}
                              >
                                <path
                                  d="M17.0695 6.062H11.3183L9.57098 1.14523C9.52616 1.03071 9.44516 0.931895 9.3389 0.862099C9.23265 0.792303 9.10624 0.754883 8.97671 0.754883C8.84719 0.754883 8.72078 0.792303 8.61452 0.862099C8.50827 0.931895 8.42727 1.03071 8.38245 1.14523L6.63514 6.062H0.844846C0.678796 6.062 0.519548 6.12416 0.402133 6.23481C0.284718 6.34546 0.218755 6.49553 0.218755 6.65201C0.218584 6.68509 0.222435 6.71809 0.230234 6.75034C0.238404 6.8336 0.266032 6.91412 0.311136 6.98612C0.35624 7.05813 0.4177 7.11983 0.491105 7.16679L5.21965 10.3076L3.40555 15.2736C3.36249 15.3913 3.36009 15.5189 3.39871 15.6381C3.43732 15.7572 3.51496 15.8618 3.62051 15.9368C3.71811 16.0193 3.84153 16.0697 3.97217 16.0804C4.11291 16.0709 4.24814 16.025 4.36295 15.9476L8.97619 12.8501L13.5894 15.9476C13.7033 16.0266 13.8391 16.0727 13.9802 16.0804C14.1104 16.0721 14.2334 16.0213 14.3282 15.9368C14.4342 15.8622 14.512 15.7575 14.5503 15.6382C14.5886 15.5188 14.5854 15.391 14.5411 15.2736L12.7296 10.3076L17.4169 7.13926L17.5301 7.04732C17.6466 6.94398 17.719 6.80409 17.7336 6.65397C17.7249 6.49376 17.651 6.34286 17.5274 6.23262C17.4037 6.12239 17.2397 6.06128 17.0695 6.062Z"
                                  fill="#FCCA25"
                                />
                              </svg>
                              <div>{item.rating}</div>
                            </div>
                          </div>
                        ))}
                      </ScrollContainer>
                    ) : isEmployeeLoading ? (
                      <GridLoader />
                    ) : null}
                  </div>

                  <div className="employee_screen_main_content_employees_date__time_wrapper">
                    <div className="employee_screen_main_content_wrapper__header">Choose Date</div>
                    <ScrollContainer style={{ display: "flex", marginTop: "20px" }}>
                      {dateItems.map((item, index) => (
                        <div
                          className="employee__screen__main__content__date__container"
                          onClick={() => {
                            setSelectedDate(
                              selectedDate == item.formattedDate ? null : item.formattedDate
                            );
                            setTimeItems([]);
                            setSelectedTime(null);
                          }}
                        >
                          <div className="employee__screen__main__content__day__name">
                            {item.dayName}
                          </div>
                          <div
                            className="employee__screen__main__content__date"
                            style={{
                              backgroundColor:
                                moment(selectedDate).format("YYYY-MM-DD") ==
                                moment(item.formattedDate).format("YYYY-MM-DD")
                                  ? "#7D8035"
                                  : null,
                              color:
                                moment(selectedDate).format("YYYY-MM-DD") ==
                                moment(item.formattedDate).format("YYYY-MM-DD")
                                  ? "white"
                                  : "black",
                            }}
                          >
                            {moment(item.formattedDate).format("DD")}
                          </div>
                        </div>
                      ))}
                    </ScrollContainer>
                  </div>

                  <div className="employee_screen_main_content_employees_date__time_wrapper">
                    <div className="employee_screen_main_content_wrapper__header">Choose Time</div>

                    <div style={{ marginTop: "20px" }}>
                      {selectedService && selectedDate && timeItems.length == 0
                        ? "No schedule Today."
                        : timeItems
                            .filter((x) => x.is_available)
                            .map((item, index) => (
                              <div
                                onClick={() => setSelectedTime(item)}
                                className="employee__screen__main__content__time"
                                style={{
                                  backgroundColor:
                                    moment(selectedTime?.value).format("YYYY-MM-DD HH:mm:00") ==
                                    moment(item.start_time).format("YYYY-MM-DD HH:mm:00")
                                      ? "#7D8035"
                                      : null,
                                  color:
                                    moment(selectedTime?.value).format("YYYY-MM-DD HH:mm:00") ==
                                    moment(item.start_time).format("YYYY-MM-DD HH:mm:00")
                                      ? "white"
                                      : "black",
                                }}
                              >
                                {moment(item.value).format("HH:mm")}
                              </div>
                            ))}
                    </div>
                  </div>
                </div>
              </div>
            ) : null}

            {step == "2" ? (
              <div className="book_app_service_buttons">
                <button
                  className="book_app_services_next_btn"
                  style={{
                    backgroundColor:
                      !selectedEmployee || !selectedDate || !selectedTime ? "#d9d9d9" : null,
                    color: !selectedEmployee || !selectedDate || !selectedTime ? "black" : null,
                  }}
                  disabled={!selectedEmployee || !selectedDate || !selectedTime ? true : false}
                  onClick={() => {
                    onAddMoreClick();
                  }}
                >
                  Continue And Add More
                </button>
              </div>
            ) : null}
          </div>

          <div className="book_app_booking_container">
            <table className="book_app_booking_table">
              <tbody>
                <tr className="book_app_booking_table_header">
                  <th className="book_app_booking_table_header_content">Service</th>
                  <th className="book_app_booking_table_header_content">Date</th>
                  <th className="book_app_booking_table_header_content">Time</th>
                  <th className="book_app_booking_table_header_content">Employee</th>
                  <th className="book_app_booking_table_header_content">Charges</th>
                </tr>
              </tbody>

              <tbody>
                {cartArray.length > 0 &&
                  cartArray.map((data, index) => (
                    <BookAppBookingsRow
                      data={data}
                      index={index}
                      onDeleteClick={onDeleteClick}
                      //   onAddClick={onAdd}
                      isLoading={isLoading}
                    />
                  ))}
              </tbody>
            </table>
          </div>

          {cartArray.length > 0 ? (
            <button
              className="book_app_checkout_btn"
              onClick={(event) => {
                window.scrollTo(0, 0);
                // window.scrollTo({ top: 0, left: 0, behavior: "smooth" })
                navigate("/billingsummary", {
                  state: { servicesData: cartArray },
                });
              }}
            >
              Checkout
            </button>
          ) : (
            <div style={{ marginBottom: "4em" }}></div>
          )}
        </div>
      </div>
      <Toaster />
    </>
  );
};

export default NewBookAppointment;
