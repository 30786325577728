import "./AppointmentDetails.css";
import LeafImage from "../../img/leaf.png";
import AppLeafImage2 from "../../img/app_det_leaf_2.png";
import AppLeafImage1 from "../../img/app_det_leaf.png";
// import UserIcon from '../../img/usericon.png'
import { useLocation, useNavigate } from "react-router-dom";
import AppDetServiceBox from "./AppDetServiceBox/AppDetServiceBox";
import moment from "moment/moment";

import UserImage from "../../img/app_det_user_img.png";
import { deleteBooking } from "../../api/apicalls";
import { CustomError } from "../Toast";
import { useState } from "react";
import InvoicePayment from "../Invoice/InvoicePayment/InvoicePayment";
import EditPaymentType from "./EditPaymentType/EditPaymentType";

const AppointmentDetails = () => {
  const [paymentpopup, setpaymentpopup] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  let [data, setData] = useState(location.state ? location.state.datatosend : "");
  const bookingType = location.state ? location.state.bookingType : "";

  const [isEditPaymentType, setIsEditPaymentType] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const onDelete = () => {
    let object = {
      ID: {
        id: data.id,
        payment_type_id: 10,
        payment_status_id: 22,
      },
    };
    setIsLoading(true);
    deleteBooking(object)
      .then(({ data }) => {
        setIsLoading(false);
        if (data.data.deleteBooking.error_code == 0) navigate("/receptionist_dashboard");
        else CustomError(data.data.deleteBooking.message);
      })
      .catch((err) => {
        setIsLoading(false);
        console.log("err", err);
      });
  };

  return (
    <>
      <EditPaymentType
        isEditPaymentType={isEditPaymentType}
        setIsEditPaymentType={setIsEditPaymentType}
        bookingData={data}
        onCallBack={(item) => {
          let newData = { ...data };
          newData.booking_transcations = item;
          setData(newData);
        }}
      />
      <div className="cover_image">
        {/* <img src={ScheduleImage} alt="" /> */}
        <p className="reception_heading">Schedule</p>
      </div>

      {/* <div>
        <img src={LeafImage} alt="" className="leaf leaf_pos" />
      </div> */}

      <div className="appointment_details_main_container_wrapper">
        <div className="appointment_details_main_container">
          <div className="appointment_details_heading">Appointment Details</div>

          <div className="app_det_container">
            <div className="app_det_leftside">
              <div className="app_det_detbox">
                <div className="app_dets_headings">
                  <div className="app_dets_headings_style">Date</div>
                  <div className="app_dets_headings_style">Time</div>
                  <div className="app_dets_headings_style">Amount</div>
                  <div className="app_dets_headings_style">Booking Type</div>
                  <div className="app_dets_headings_style">Payment Status</div>
                  <div className="app_dets_headings_style">Payment Mode</div>
                </div>

                <div className="app_dets_content">
                  <div className="app_dets_content_style">
                    {data &&
                      moment(data.booking_details[0].start_datetime.split(".")[0]).format(
                        "D MMM YY"
                      )}
                  </div>
                  <div className="app_dets_content_style">
                    {data &&
                      moment(data.booking_details[0].start_datetime.split(".")[0]).format("LT") +
                        " - " +
                        moment(data.booking_details[0].end_datetime.split(".")[0]).format("LT")}
                  </div>
                  <div className="app_dets_content_style">SAR {data && data.net_price}</div>
                  <div className="app_dets_content_style">
                    {data && data.booking_type_id === 6 ? "Online" : "Onsite"}
                  </div>
                  <div className="app_dets_content_style">
                    {data && data.payment_status_id === 13
                      ? "Paid"
                      : data.payment_status_id == 22
                      ? "Refunded"
                      : "Unpaid"}
                  </div>
                  <div className="app_dets_content_style">
                    {data
                      ? data.booking_transcations.length > 0
                        ? data.booking_transcations
                            .map((item) =>
                              item.payment_type_id == 9
                                ? "Online"
                                : item.payment_type_id == 10
                                ? "Cash"
                                : item.payment_type_id == 23
                                ? "Card"
                                : item.payment_type_id == 24
                                ? "Bank Transfer"
                                : item.payment_type_id == 25
                                ? "Gift Card"
                                : "---"
                            )
                            .join(", ")
                        : data.payment_status_id === 13 || data.payment_status_id == 22
                        ? data.payment_type_id == 9
                          ? "Online"
                          : data.payment_type_id == 10
                          ? "Cash"
                          : data.payment_type_id == 23
                          ? "Card"
                          : data.payment_type_id == 24
                          ? "Bank Transfer"
                          : data.payment_type_id == 25
                          ? "Gift Card"
                          : "---"
                        : "---"
                      : "---"}
                  </div>
                </div>

                {/* <img src={AppLeafImage2} alt="Leaf" className="app_det_leaf2" /> */}
              </div>

              <div className="app_dets_buttons">
                {/* <button
                  className="app_det_invoice_btn"
                  onClick={onDelete}
                  disabled={isLoading}
                >
                  {isLoading ? "Processing..." : "Refund"}
                </button> */}

                {data &&
                data.net_price > 0 &&
                (data.payment_status_id === 13 || data.status_id == 4) ? (
                  <button
                    className="app_det_mod_btn"
                    onClick={(event) => setIsEditPaymentType(true)}
                  >
                    Modify Payment Type
                  </button>
                ) : null}

                {data && (data.payment_status_id == 13 || data.payment_status_id == 22) ? null : (
                  <button
                    className="app_det_mod_btn"
                    onClick={(event) => {
                      navigate("/modifybooking", {
                        state: {
                          datatosend: data,
                        },
                      });
                    }}
                  >
                    Modify
                  </button>
                )}
                <button
                  className="app_det_invoice_btn"
                  onClick={(event) => {
                    navigate("/invoice", {
                      state: {
                        datatosend: data,
                      },
                    });
                  }}
                >
                  {data &&
                  (data.payment_status_id === 13 ||
                    data.payment_status_id === 22 ||
                    data.status_id == 2)
                    ? "Invoice"
                    : "Generate Invoice"}
                </button>
                {/* )} */}
                {/* {data.payment_status_id === 13 ||
                data.payment_status_id === 22 ? null : (
                  <button
                    className="inv_paynow_btn"
                    onClick={() => {
                      setpaymentpopup(true);
                    }}
                  >
                    Pay Now
                  </button>
                )} */}
              </div>
            </div>

            <div className="app_det_rightside">
              <div className="customer_det_box">
                {/* <img src={AppLeafImage1} alt="Leaf" className="app_det_leaf_1" /> */}

                <div className="customer_det_subbox1">
                  <img
                    src={
                      data && data.customer_image
                        ? process.env.REACT_APP_BASEURL_ONLY.concat(data.customer_image)
                        : UserImage
                    }
                    alt="user img"
                  />
                  {/* <div className="image_container">
                                      <img src={UserIcon} alt="usericon" />
                                  </div> */}

                  <div className="customer_name_style">{data && data.customer_name}</div>
                </div>

                <div className="customer_det_subbox2">
                  <div className="customer_number_label_style">Number</div>

                  <div className="customer_number_style">{data.customer_phone}</div>
                </div>
              </div>

              <div className="app_det_services">
                {data &&
                  data.booking_details.length > 0 &&
                  data.booking_details.map((item) => <AppDetServiceBox data={item} />)}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AppointmentDetails;
