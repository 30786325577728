import "./InvoicePayment.css";
import Modal from "react-modal";
import { useEffect, useState } from "react";
import Select from "react-select";
import { changeBookingStatus } from "../../../api/apicalls";
import { useNavigate } from "react-router-dom";
import { requestAnimationFramePolyfill } from "rsuite/esm/DOMHelper";
import { CustomError } from "../../Toast";

const disableScrollOnOpen = () => {
  document.body.style.overflow = "hidden";
};
const disableScrollOnClose = () => {
  document.body.style.overflow = "auto";
};

const InvoicePayment = ({
  paymentpopup,
  setpaymentpopup,
  netAmount,
  bookingId,
  isOnBillingSummary,
  onPayClick,
  isBookingLoading,
}) => {
  const navigate = useNavigate();
  const [amount, setAmount] = useState(netAmount);
  // const [paymentType, setPaymentType] = useState({ value: 10, label: "Cash", amount:"" });
  const [paymentType, setPaymentType] = useState([]);
  const [paymentTypesAmount, setPaymentTypesAmount] = useState([]);
  const [isLoading, setIsLoading] = useState(isBookingLoading ? isBookingLoading : false);

  useEffect(() => {
    setAmount(netAmount);
  }, [netAmount]);

  const payment = () => {
    const receptionistId = localStorage.getItem("receptionistId");

    let paymentDetails = [];
    for (let index = 0; index < paymentTypesAmount.length; index++) {
      const element = paymentTypesAmount[index];
      let object = {
        payment_type_id: element.value,
        amount: parseFloat(element.amount),
      };
      paymentDetails.push(object);
    }

    let requestObj = {
      paymentModel: {
        id: bookingId,
        // payment_type_id: paymentType.value,
        paymentDetails: paymentDetails,
        payment_status_id: 13,
        receptionist_id: JSON.parse(receptionistId),
      },
    };
    debugger;
    setIsLoading(true);
    changeBookingStatus(requestObj)
      .then(({ data }) => {
        setIsLoading(false);
        debugger;
        if (data.data.changeBookingStatus.error_code == 0) {
          setpaymentpopup(data.data.changeBookingStatus.result.qr_string);
          navigate("/receptionist_dashboard");
        } else CustomError(data.data.changeBookingStatus.message);
      })
      .catch((err) => {
        setIsLoading(false);
        console.log("err", err);
      });
  };

  const pay = () => {
    let totalAmount = 0;
    for (let index = 0; index < paymentTypesAmount.length; index++) {
      const element = paymentTypesAmount[index];
      totalAmount += parseFloat(element.amount);
    }
    debugger;
    if (totalAmount > netAmount) CustomError("Total amount is greater than net amount.");
    else payment();
    // let paymentModel = {
    //   id: bookingId,
    //   payment_type_id: paymentType.value,
    //   payment_status_id: 13,
    // };
    // if (isOnBillingSummary) onPayClick(paymentModel);
    // else payment();
  };

  return (
    <Modal
      className={"inv_payment_container"}
      overlayClassName="modal_Overlay"
      onAfterOpen={disableScrollOnOpen}
      onAfterClose={disableScrollOnClose}
      isOpen={paymentpopup}
      shouldCloseOnOverlayClick={true}
      onRequestClose={() => {
        setpaymentpopup(false);
      }}
      shouldCloseOnEsc={true}
    >
      <div className="inv_payment_heading">Payment</div>

      <div className="inv_payment_paymentmethod_dropdown_wrapper">
        {/* <select
          name="payment_method"
          id="payment_method"
          className="inv_payment_paymentmethod_dropdown"
        >
          <option value="payment_method">Select Payment Method</option>
          <option value="online">Online</option>
          <option value="onsite">On-Site</option>
        </select> */}

        <div className="inv_payment_heading" style={{ marginTop: "10px" }}>
          <div> Net Amount: {netAmount} </div>
        </div>

        <Select
          isMulti
          options={[
            { value: 10, label: "Cash", amount: "" },
            { value: 9, label: "Online", amount: "" },
            { value: 23, label: "Card", amount: "" },
            { value: 24, label: "Bank Transfer", amount: "" },
          ]}
          // value={paymentType}
          value={paymentTypesAmount}
          name="payment_types"
          onChange={(value) => {
            let newAmount = amount;
            newAmount = amount / value.length;
            for (let index = 0; index < value.length; index++) {
              const element = value[index];
              element.amount = newAmount.toFixed(2);
            }
            setPaymentTypesAmount(value);
            // setPaymentType(value)
          }}
          className="book_app_customer_input__payment"
          theme={(theme) => ({
            ...theme,
            borderRadius: 0,
            colors: {
              ...theme.colors,
              primary75: "#2a5e59",
              primary25: "#2a5e595e",
              primary50: "#2a5e595e",
              primary: "#2a5e59",
            },
          })}
          styles={{
            control: (base, state) => ({
              ...base,
              "&:hover": {
                borderColor: "transparent",
                borderBottom: "1px solid #DFDFDF",
              }, // border style on hover
              border: "1px solid transparent", // default border color
              boxShadow: "none", // no box-shadow
              borderRadius: "30px",
              marginRight: "0.5em",
              background: "#EFEFEF",
              fontFamily: "Montserrat",
              height: "50px",
            }),
          }}
        />
      </div>

      {/* <input
        type="text"
        placeholder="Enter amount"
        className="inv_payment_amount_input"
        value={amount}
        onChange={(e) => {
          setAmount(e.currentTarget.value);
        }}
      /> */}

      {paymentTypesAmount.map((item, index) => (
        <div className="inv_payment_amount_input_container">
          <div style={{ fontSize: "16px" }}>{item.label}</div>
          <input
            type="text"
            placeholder="Enter amount"
            className="inv_payment_amount_input"
            value={item.amount}
            onChange={(e) => {
              let newArray = [...paymentTypesAmount];
              newArray[index].amount = e.target.value;
              setPaymentTypesAmount(newArray);
            }}
          />
        </div>
      ))}

      <button className="inv_payment_pay_btn" onClick={() => pay()}>
        {isLoading ? "----" : "Pay"}
      </button>
    </Modal>
  );
};

export default InvoicePayment;
